import { Container, Flex, Table, Text, Tooltip } from "@mantine/core";
import "./ProfilePages.scss";
import { useCallback, useEffect, useMemo } from "react";
import { useAuth } from "../../AuthenticationProvider";
import moment from "moment";
import { IconCopy } from "@tabler/icons-react";
import { useClipboard } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { PageTitle } from "../PageTitle/PageTitle";
import { HomeMenu } from "../Menu/HomeMenu";
import { getUserAccount, homePageOptions } from "../helpers";

export const ApiKeyPage = () => {
  const {
    apiKey,
    apiKeyCreationDate,
    currentUserEmail,
    setApiKey,
    setCurrentUserName,
    setApiKeyCreationDate,
  } = useAuth();
  useEffect(() => {
    if (
      currentUserEmail &&
      setApiKey &&
      setCurrentUserName &&
      setApiKeyCreationDate
    ) {
      getUserAccount(currentUserEmail)
        .then((resp) => resp.data)
        .then(({ userApiKey, fullName, apiKeyCreationDate }) => {
          setApiKey(userApiKey);
          setCurrentUserName(fullName);
          setApiKeyCreationDate(apiKeyCreationDate);
        });
    }
  }, [currentUserEmail, setApiKey, setCurrentUserName, setApiKeyCreationDate]);

  const elements = useMemo(
    () => [
      {
        apiKey: apiKey,
        description: "demo-api-key",
        creationDate: apiKeyCreationDate,
        name: "free-api-key-1",
      },
    ],
    [apiKey, apiKeyCreationDate]
  );
  const clipboard = useClipboard({ timeout: 500 });
  const onCopyIconClick = useCallback(() => {
    clipboard.copy(apiKey);
    notifications.show({
      loading: false,
      title: "API key copied to clipboard!",
      autoClose: 1000,
      color: "green",
    });
  }, [apiKey, clipboard]);

  const rows = elements.map((element) => (
    <Table.Tr key={element.name}>
      <Table.Td>
        <Text>{element.description}</Text>
      </Table.Td>
      <Table.Td>
        <Text w="8rem" lineClamp={1}>
          {element.apiKey}
        </Text>
      </Table.Td>
      <Table.Td>{moment(element.creationDate).format("LL")}</Table.Td>
      <Table.Td>
        <div>
          <Tooltip label="Copy api key">
            <IconCopy
              className="icon--copy"
              onClick={() => {
                onCopyIconClick();
              }}
            />
          </Tooltip>
        </div>
      </Table.Td>
    </Table.Tr>
  ));
  return (
    <Flex>
      <HomeMenu selected={homePageOptions.ACCOUNT_API_KEYS} />
      <Container size="responsive" w="100%" m={0} p={5}>
        <PageTitle title={"API keys"} />
        <div className="container--api-keys">
          <Text mb={3}>All your API keys are displayed here.</Text>
          <Text>
            For the free version only one API key can be used and that is the
            demo-api-key.
          </Text>
        </div>
        <div className="container--api-keys transparent-caret">
          <Table p={10}>
            <Table.Thead p="xs">
              <Table.Tr>
                <Table.Th>description</Table.Th>
                <Table.Th>api key</Table.Th>
                <Table.Th>creation date</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
        </div>
      </Container>
    </Flex>
  );
};
