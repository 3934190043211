import { Space, Text } from "@mantine/core";
import { ModelDescriptionContainer } from "../ModelDescriptionContainer";
import {
  IconNumber1,
  IconNumber2,
  IconNumber3,
  IconNumber4,
} from "@tabler/icons-react";
import { TimelineContainer } from "../TimelineContainer";
import "../ModelsPages.scss";

const timelineItems = [
  {
    title: "Input type and constraints",
    bullet: <IconNumber1 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        For demo, the input is of type text and the maximum length is
        approximately equal to 2 pages of text.
      </Text>
    ),
  },
  {
    title: "Select output format",
    bullet: <IconNumber2 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        You can choose in which format to display the results of the Text QA
        model processing(text or json).
      </Text>
    ),
  },
  {
    title: "Enter your question",
    bullet: <IconNumber3 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        Add your question about the text in the indicated space adnotated with a
        "?" mark.
        <Space />
        Remember that the question should match the topic and content of the
        given text.
      </Text>
    ),
  },
  {
    title: "Check the answer to your question",
    bullet: <IconNumber4 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        After the model finished processing your input and has come up with an
        answer for your question you can find it in the "Results" section.
      </Text>
    ),
  },
];
export const ModelDescriptionCardTextQa = () => {
  return (
    <ModelDescriptionContainer
      introduction={
        <Text>
          The <span className="wrapper--span-blue-filled">Text QA</span> model
          is used to answer a question based on a given context (a paragraph of
          text, a document etc...). It's like asking something about the text.
          <Space />
          The answer to the question is a segment of the given context.
        </Text>
      }
      languages={
        <Text>
          For custom solutions we support up to{" "}
          <b className="wrapper--span-blue-filled">100 languages</b>
          .
          <Space />
          The Trial account model performs the best only on{" "}
          <b>English, Romanian, Spanish and French.</b>
        </Text>
      }
      example={
        <Text w="100%">
          Given the following text about Albert Einstein took from Wikipedia:{" "}
          <Space h="md" />
          <Text ml="xs">
            "Albert Einstein was a German-born theoretical physicist who is
            widely held to be one of the greatest and most influential
            scientists of all time. <Space h="xs" />
            Best known for developing the theory of relativity, Einstein also
            made important contributions to quantum mechanics, and was thus a
            central figure in the revolutionary reshaping of the scientific
            understanding of nature that modern physics accomplished in the
            first decades of the twentieth century.
            <Space h="xs" />
            His mass–energy equivalence formula E = mc2, which arises from
            relativity theory, has been called 'the world's most famous
            equation'. He received the 1921 Nobel Prize in Physics 'for his
            services to theoretical physics, and especially for his discovery of
            the law of the photoelectric effect', a pivotal step in the
            development of quantum theory."
          </Text>
          <Space h="md" />
          We ask the model when did Albert Einstein receive the Nobel Prize?
          <Space h="md" />
          The expected model ouput would be: <b>1921</b>
          <Space h="xs" />
          The model spotted the exact place of the answer in the given text "He
          received the <b>1921</b> Nobel Prize in Physics". Remember that the
          answer is taken from the given text(context).
        </Text>
      }
      quickStart={<TimelineContainer timelineItems={timelineItems} />}
    ></ModelDescriptionContainer>
  );
};
