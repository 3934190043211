import { useState, useCallback } from "react";
import { useAuth } from "../../../AuthenticationProvider";
import { Group, NumberInput, Paper, Stack } from "@mantine/core";
import { NerInputText } from "../Ner/NerInputText";
import { RunModelControls } from "../RunModelControls";
import { callModel } from "../../helpers";
import { useInfoModal } from "../../../InfoModalProvider";

const textSummarizationModelLimits = {
  minWordsCount: {
    min: 40,
    max: 149,
  },
  maxWordCount: {
    min: 150,
    max: 200,
  },
};
const defaultModelInput = `
Simona Halep says she has been under huge stress as the two-time grand slam winner bids to clear her name and return to tennis following a positive drug test.
Halep tested positive for Roxadustat, a banned substance listed on the 2022 World Anti-Doping Agency (WADA) prohibited list, at last year’s US Open and has been provisionally suspended since October.
She has denied that she knowingly took a banned substance, instead arguing that one of her supplements had been contaminated.
According to the United States Anti-Doping Agency (USADA), Roxadustat is a class of drug called HIF-stabilizing agents, which can increase the delivery of oxygen to muscles and boost endurance.
“The situation has been really hard. Emotionally, it’s heavy. The stress is huge because I never thought I would face something like this,” Halep said in an interview with Tennis Majors released on Thursday.
“I have always been against doping as I am a big supporter of clean sports. At the beginning, I didn’t know how to handle it. Over time, I just tried to stay calm and actually, I feel confident because I know I’m clean and I didn’t take anything knowingly that is banned.”
Halep’s coach, Patrick Mouratoglou, is one of the co-founders and shareholders of Tennis Majors.
`;
const controller = new AbortController();
const signal = controller.signal;
export const UserInputCard = ({ setResults }) => {
  const { open, setModalMessage } = useInfoModal();
  const [modelRunning, setModelRunning] = useState(false);
  const [modelInput, setModelInput] = useState(defaultModelInput);
  const [summaryMinSize, setSummaryMinSize] = useState();
  const [summaryMaxSize, setSummaryMaxSize] = useState();
  const { apiKey } = useAuth();
  const onRunModelClick = useCallback(() => {
    if (apiKey && modelInput && setResults) {
      const body = {
        text: modelInput,
        summaryMinSize: summaryMinSize
          ? summaryMinSize
          : textSummarizationModelLimits.minWordsCount.min,
        summaryMaxSize: summaryMaxSize
          ? summaryMaxSize
          : textSummarizationModelLimits.maxWordCount.max,
      };
      setModelRunning(true);
      const formData = new FormData();
      formData.append("apiKey", apiKey);
      formData.append("text", body.text);
      formData.append("summaryMinSize", body.summaryMinSize);
      formData.append("summaryMaxSize", body.summaryMaxSize);
      callModel({
        setModelRunning,
        setResults,
        formData,
        modelNr: 4,
        controller,
        signal,
        openInfoModal: open,
        setModalMessage,
      });
    }
  }, [
    apiKey,
    modelInput,
    setModelRunning,
    setResults,
    summaryMaxSize,
    summaryMinSize,
    open,
    setModalMessage,
  ]);
  const onWordsCountChange = (nrInput, value) => {
    switch (nrInput) {
      case "min":
        setSummaryMinSize(value);
        break;
      case "max":
        setSummaryMaxSize(value);
        break;
      default:
        break;
    }
  };

  return (
    <Paper withBorder radius="md">
      <Stack justify="flex-start" h="100%" gap={0}>
        <Group
          align="flex-end"
          justify="space-between"
          p="2rem"
          gap="6rem"
          pb="0.5rem"
        >
          <Group>
            <NumberInput
              description="Summary min words count"
              placeholder={`Value between ${textSummarizationModelLimits.minWordsCount.min} and ${textSummarizationModelLimits.minWordsCount.max}`}
              min={textSummarizationModelLimits.minWordsCount.min}
              max={textSummarizationModelLimits.minWordsCount.max}
              styles={{
                input: {
                  color: "var(--mantine-color-blue-filled)",
                },
                description: { fontWeight: 700 },
              }}
              onChange={(event) => onWordsCountChange("min", event)}
              defaultValue={50}
            />
            <NumberInput
              description="Summary max words count"
              placeholder={`Value between ${textSummarizationModelLimits.maxWordCount.min} and ${textSummarizationModelLimits.maxWordCount.max}`}
              min={textSummarizationModelLimits.maxWordCount.min}
              max={textSummarizationModelLimits.maxWordCount.max}
              styles={{
                input: {
                  color: "var(--mantine-color-blue-filled)",
                },
                description: { fontWeight: 700 },
              }}
              onChange={(event) => onWordsCountChange("max", event)}
              defaultValue={200}
            />
          </Group>
          <RunModelControls
            modelRunning={modelRunning}
            modelInput={modelInput}
            onRunModelClick={onRunModelClick}
          />
        </Group>
        <NerInputText
          setModelInput={setModelInput}
          currentModelInput={modelInput}
        />
      </Stack>
    </Paper>
  );
};
