import { Grid } from "@mantine/core";
import { NerEntityPill } from "./NerEntityPill";

export const TagValue = ({
  entity: detectedEntityGroup,
  position: entityPositionsInTextInput,
  probability: probabilityForEachEntityInGroup,
}) => {
  const entityPositionStart = entityPositionsInTextInput[0];
  const entityPositionEnd =
    entityPositionsInTextInput[entityPositionsInTextInput?.length - 1];
  const probabilityAverageForEntityGroup =
    probabilityForEachEntityInGroup?.reduce((a, b) => a + b, 0) /
    probabilityForEachEntityInGroup?.length;
  return (
    <Grid.Col span="content">
      <NerEntityPill
        description={detectedEntityGroup?.join(" ")}
        tooltip={`position: ${
          entityPositionsInTextInput?.length > 1
            ? entityPositionStart + "-" + entityPositionEnd
            : entityPositionStart
        }\n confidence: ${probabilityAverageForEntityGroup.toFixed(4)}`}
        forResults={true}
      />
    </Grid.Col>
  );
};
