import { Loading } from "../Loading";
import { verifyToken } from "./helpers";
import { Navigate } from "react-router-dom";
import { useState, useEffect } from "react";

export const AuthenticationProtectedRoute = ({ children }) => {
  const [isTokenVerified, setIsTokenVerified] = useState(false);
  const [tokenVerifyLoading, setTokenVerifyLoading] = useState(true);
  useEffect(() => {
    setTokenVerifyLoading(true);
    const load = async () => {
      const isVerified = await verifyToken();
      if (isVerified) {
        setIsTokenVerified(true);
      } else {
        setIsTokenVerified(false);
      }
      setTokenVerifyLoading(false);
    };
    load();
  }, []);

  return (
    <>
      {tokenVerifyLoading === true && <Loading />}
      {isTokenVerified === true && tokenVerifyLoading === false && children}
      {isTokenVerified === false && tokenVerifyLoading === false && (
        <Navigate to={"/login"} />
      )}
    </>
  );
};
