import { PageTitle } from "../../PageTitle/PageTitle";
import { Container, Flex, Group, Stack } from "@mantine/core";
import { UserInputCard } from "./UserInputCard";
import { ModelDescriptionCard } from "./ModelDescriptionCard";
import { ModelResultsCard } from "./ModelResultsCard";
import { useEffect, useState } from "react";
import { HomeMenu } from "../../Menu/HomeMenu";
import { useAuth } from "../../../AuthenticationProvider";
import { getUserAccount, homePageOptions } from "../../helpers";

export const TextClassificationPage = () => {
  const { currentUserEmail, setApiKey, setCurrentUserName, apiKey } = useAuth();
  useEffect(() => {
    if (!apiKey) {
      if (currentUserEmail && setApiKey && setCurrentUserName) {
        getUserAccount(currentUserEmail)
          .then((resp) => resp.data)
          .then(({ userApiKey, fullName }) => {
            setApiKey(userApiKey);
            setCurrentUserName(fullName);
          });
      }
    }
  }, [currentUserEmail, setApiKey, setCurrentUserName, apiKey]);
  const [results, setResults] = useState();
  return (
    <Flex>
      <HomeMenu selected={homePageOptions.PLAYGROUND_TEXT_CLASSIFICATION} />{" "}
      <Container size="responsive" w="100%" m={0} p={5} mt={10} bg="#F9F9F9">
        <PageTitle title={homePageOptions.PLAYGROUND_TEXT_CLASSIFICATION} />
        <Group justify="space-between" grow align="flex-start" gap={0}>
          <Stack justify="flex-start" p={10} gap="0.5rem" w={"60rem"}>
            <UserInputCard setResults={setResults} />
            <ModelDescriptionCard />
          </Stack>
          <ModelResultsCard results={results} />
        </Group>
      </Container>
    </Flex>
  );
};
