import { GeneralModelResultsCard } from "../GeneralModelResultsCard";
import { GraphFormatResults } from "./GraphFormatResults";
import { TextFormatResults } from "./TextFormatResults";

export const ModelResultsCard = ({ results }) => {
  return (
    <GeneralModelResultsCard
      results={results}
      textFormatResultsComponent={<TextFormatResults results={results} />}
      graphFormatResultsComponent={<GraphFormatResults results={results} />}
      withGraph
    />
  );
};
