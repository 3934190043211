import React, { memo, useEffect, useState } from "react";
import Graph from "react-graph-vis";

const defaultGraph = {
  nodes: [],
  edges: [],
};

const defaultOptions = {
  layout: {
    hierarchical: true,
  },
  edges: {
    color: { inherit: "from" },
    width: 0.15,
    arrows: {
      to: {
        enabled: false,
      },
    },
  },
  nodes: {
    shape: "dot",
    fixed: false,
  },
  height: "400px",
};

export const EntityRelationsGraph = memo(({ graph }) => {
  const [network, setNetwork] = useState();
  const [selectedEdge, setSelectedEdge] = useState(1);
  network?.on("click", (event) => {
    if (event?.edges[0]) setSelectedEdge(event.edges[0]);
  });
  useEffect(() => {
    if (graph && network) {
      network.setData({ nodes: graph.nodes, edges: graph.edges });
      if (selectedEdge) {
        const edgeInGraph = graph.edges.find(
          (elem) => elem.id === selectedEdge
        );
        if (edgeInGraph) {
          network.clustering.updateEdge(selectedEdge, {
            color: "#aa0000",
            label: graph.labels ? graph.labels[selectedEdge - 1] : "",
            width: 0.15,
          });
        }
      }
    }
  }, [graph, network, selectedEdge]);

  return (
    <>
      <Graph
        graph={defaultGraph}
        options={defaultOptions}
        getNetwork={(network) => setNetwork(network)}
        shouldAutoResizeEdgeLabels={false}
        className="vis-item"
      />
    </>
  );
});
