import { useDisclosure } from "@mantine/hooks";
import { createContext, useContext, useMemo, useState } from "react";

const InfoModalContext = createContext();
export const InfoModalProvider = ({ children }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [modalMessage, setModalMessage] = useState();
  const contextValue = useMemo(
    () => ({
      open,
      close,
      opened,
      modalMessage,
      setModalMessage,
    }),
    [open, close, opened, modalMessage, setModalMessage]
  );
  return (
    <InfoModalContext.Provider value={contextValue}>
      {children}
    </InfoModalContext.Provider>
  );
};
export const useInfoModal = () => {
  return useContext(InfoModalContext);
};
