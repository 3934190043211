const jsonTextClassificationResponseBody = {
  modelResponse: "similarity score by topic",
};

export const prepareResultsForDisplay = (response) => {
  let result = [];
  for (const [category, confidenceValue] of Object.entries(
    response[jsonTextClassificationResponseBody.modelResponse]
  )) {
    result.push({
      category,
      confidence: `${Math.floor(
        parseFloat(confidenceValue)?.toFixed(2) * 100
      )}%`,
    });
  }
  return result;
};
