const jsonOpenDomainQaResponseBody = {
  searchResults: "results",
  modelResponse: "searchout",
};

export const prepareResultsForDisplay = (response) => {
  let results = response[jsonOpenDomainQaResponseBody.modelResponse]?.[
    jsonOpenDomainQaResponseBody.searchResults
  ].map((searchResult) => {
    return {
      ...searchResult,
      score: `${Math.floor(
        parseFloat(searchResult?.score)?.toFixed(2) * 100
      )}%`,
    };
  });
  return results;
};
