import { useEffect, useState } from "react";
import { GeneralModelResultsCard } from "../GeneralModelResultsCard";
import { TextFormatResults } from "../TextSummarization/TextFormatResults";
import { prepareResultsForDisplay } from "./helpers";

export const ModelResultsCard = ({ results }) => {
  const [resultDisplayed, setResultDisplayed] = useState(false);
  const [textFormatResult, setTextFormatResult] = useState();
  useEffect(() => {
    setResultDisplayed(false);
    if (results) {
      setTextFormatResult(prepareResultsForDisplay(results)?.generatedText);
    }
  }, [results]);
  return (
    <GeneralModelResultsCard
      results={results}
      textFormatResultsComponent={
        <TextFormatResults
          result={textFormatResult}
          resultDisplayed={resultDisplayed}
          setResultDisplayed={setResultDisplayed}
        />
      }
    />
  );
};
