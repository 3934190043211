import { onAuthStateChanged } from "firebase/auth";
import { firebaseAuth } from "../firebase/firebaseInit";
import { createContext, useState, useEffect, useContext, useMemo } from "react";

export const AuthenticationContext = createContext();
export const AuthenticationProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [currentUserName, setCurrentUserName] = useState("");
  const [currentOpenedPage, setCurrentOpenedPage] = useState("");
  const [apiKeyCreationDate, setApiKeyCreationDate] = useState();

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        setCurrentUserEmail(user.email);
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
        setCurrentUserEmail(null);
      }
    });
  }, [currentUser]);

  const contextValue = useMemo(
    () => ({
      currentUser,
      currentUserEmail,
      apiKey,
      setApiKey,
      currentUserName,
      setCurrentUserName,
      currentOpenedPage,
      setCurrentOpenedPage,
      apiKeyCreationDate,
      setApiKeyCreationDate,
    }),
    [
      currentUser,
      currentUserEmail,
      apiKey,
      setApiKey,
      currentUserName,
      currentOpenedPage,
      setCurrentOpenedPage,
      apiKeyCreationDate,
      setApiKeyCreationDate,
    ]
  );

  return (
    <AuthenticationContext.Provider value={contextValue}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthenticationContext);
};
