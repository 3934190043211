import { Grid } from "@mantine/core";
import { TagValue } from "./TagValue";

export const ContainerForTagValues = ({ entityTagValues }) => {
  return (
    <Grid justify="flex-start">
      {entityTagValues?.map((value) => {
        return (
          <TagValue
            position={value?.position}
            entity={value?.entity}
            probability={value?.probability}
          />
        );
      })}
    </Grid>
  );
};
