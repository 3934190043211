import { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import {
  TextInput,
  Button,
  Title,
  Container,
  Text,
} from "@mantine/core";
import { Header } from "../Header/Header";
import { notifications } from "@mantine/notifications";
import { validateCompanyEmail } from "../SingUpPage/helpers";
import { firebaseAuth } from "../../firebase/firebaseInit";
import "./PasswordResetPage.scss"

export const EmailForPasswordReset = () => {
  const [email, setEmail] = useState("");
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const onEmailChange = (event, isInvalid = false) => {
    if (isInvalid) resetInvalidInputs();
    setEmail(event.target.value);
  };
  const resetInvalidInputs = () => {
    setInvalidCredentials(false);
  };
  const onSendVerificationEmailClicked = () => {
    if (!validateCompanyEmail(email)) {
      setInvalidCredentials(true);
    } else {
      const id = notifications.show({
        color: "teal",
        loading: true,
        title: "Sending reset password email!",
        autoClose: false,
        withCloseButton: true,
      });
      sendPasswordResetEmail(firebaseAuth, email).then((res) => {
        notifications.update({
          id,
          loading: false,
          title: "Reset password email sent!",
          message: "Verify your inbox and access the reset password link",
          autoClose: 3500,
          color: "green",
        });
      });
    }
  };
  return (
    <Container size={420}>
      <Header />
      <div className="wrapper">
        <div className="container__text-inputs--centered">
          <Title mb={18} size="2rem" className="title" ta="center">
            Password reset
          </Title>
          {invalidCredentials ? (
            <TextInput
              label="Company email"
              placeholder="Invalid company email"
              mt="lg"
              size="xl"
              className="input--text"
              withAsterisk
              error
              onChange={(event) => onEmailChange(event, true)}
            />
          ) : (
            <TextInput
              label="Company email"
              placeholder=""
              mt="lg"
              size="xl"
              className="input--text"
              withAsterisk
              onChange={onEmailChange}
            />
          )}

          <Button
            fullWidth
            mt="xl"
            size="lg"
            onClick={onSendVerificationEmailClicked}
          >
            Reset password
          </Button>
          <Text mt="1rem" ta="center">
            <a href="/login" className="container--back-to-login">
              Back to Login
            </a>
          </Text>
        </div>
      </div>
    </Container>
  );
};
