import { CheckIcon, Group, Radio, Stack, Paper } from "@mantine/core";
import { useCallback, useState } from "react";
import { NerInputText } from "./NerInputText";
import { NerInputFile } from "./NerInputFile";
import { useAuth } from "../../../AuthenticationProvider";
import { RunModelControls } from "../RunModelControls";
import { callModel } from "../../helpers";
import { useInfoModal } from "../../../InfoModalProvider";
const INPUT_TYPE_TEXT = "text";
const INPUT_TYPE_FILE = "file";
// const defaultInput = `
// Colorado's Supreme Court has ruled that Donald Trump cannot run for president next year in the state, citing a constitutional insurrection clause. The court ruled 4-3 that Mr Trump was not an eligible candidate because he had engaged in an insurrection over the US Capitol riot nearly three years ago. It does not stop Mr Trump running in the other states and his campaign says it will appeal to the US Supreme Court. The decision, they said, was "completely flawed". The ruling only mentions the state's primary election on 5 March, when Republican voters will choose their preferred candidate for president. But it could affect the general election in Colorado next November. It is the first ever use of Section 3 of the US Constitution's 14th Amendment to disqualify a presidential candidate. Tuesday's decision - which has been placed on hold pending appeal until next month - only applies in Colorado. Similar attempts to kick Mr Trump off the ballot in New Hampshire, Minnesota and Michigan have failed. The justices wrote in their ruling: "We do not reach these conclusions lightly. We are mindful of the magnitude and weight of the questions now before us. "We are likewise mindful of our solemn duty to apply the law, without fear or favor, and without being swayed by public reaction to the decisions that the law mandates we reach." The decision reverses an earlier one from a Colorado judge, who ruled that the 14th Amendment's insurrection ban did not apply to presidents because the section did not explicitly mention them.
// `;
const defaultInput = `
Deficitul bugetar a fost în 2023 de 5,7% din PIB, un deficit enorm pe care România nu-l mai poate suporta.

Cu câteva sute de ani în urmă, în frumoasa şi calda Grecie, navele Imperiului Britanic au intrat Mediterana, s-au apropiat de portul Pireu şi au pus tunurile pe el: ne daţi banii sau aleluia. Grecii împrumutaseră de la englezi sume mari de bani, dar, risipitori, i-au cheltuit şi au intrat în faliment. Şi, cum grecii nu aveau bani, englezii le-au luat frescele din templele din Atena pe care grecii le admiră azi la British Museum. Acum plâng după ele, dar istoria nu mai poate fi întoarsă.

Sunt patru, de acum cinci, ani în care deficitul bugetar al României sare de 5% din PIB. Este o îndatorare care va costa cumplit pentru viitor.

Politicienii cred că deficitele de 6% din PIB sunt normale, dar nu sunt. Ele sunt enorme de fapt, pentru că se adaugă la datoria publică, spune economistul Ionuţ Dumitru. Potrivit datelor publicate de INS, România, veniturile au crescut în 2023 cu 13,3%, iar cheltuielile cu 13%. Avem, prin urmare, venituri mai mari în 2013 decât cheltuielile – e drept, aproape de nebăgat în seamă. Am avut, prin urmare, un deficit aproape identic cu cel din 2022 (5,76% din PIB în 2022 faţă de 5,68% în 2023). Dar deficitul se adaugă, an de an, la datoria publică, ce a depăşit 50% din PIB.

Problema este a bugetului din 2023. Guvernul şi-a propus cheltuieli mai mici şi venituri mai mari. Şi nu i-a ieşit.

Guvernul nu a reuşit să ţină creşterea cheltuielilor la 10% cum şi-a propus la început de an 2023 şi ele au crescut cu 13%, an/an. Şi nici nu a reuşit să ducă creşterea veniturilor la un 15% cum îşi propusese şi care s-au rezumat la 13,3%. Prin urmare, ţinta de deficit de 4,4% din PIB stabilită în legea bugetului pentru 2023 nu a putut fi atinsă. Rotunjind, deficitul a fost de 5,7% anul trecut, adică 90 de miliarde de lei (18 mld. euro).

Iar lucrurile ar fi fost mult mai rele în absenţa fondurilor Uniunii Europene, spune economistul Adrian Codirlaşu. 
Fără fondurile UE şi doar judecând în baza veniturilor fiscale ale României, deficitul bugetar ar fi fost la 8,8% din PIB anul trecut.

Dar sunt şi optimişti. Nu este un capăt de ţară, chiar dacă deficitul este mai mult decât decât cel propus, spune economistul Laurian Lungu. Au fost crize peste crize, în ultimii ani şi, iată, economiile merg.

„Că o fi o creştere de 0,6% în zona euro, cum se anticipează, că va fi un pic mai puţin, ei bine, economiile cresc şi este important. Deficitul? România este în continuare capabilă să-l controleze”, spune Laurian Lungu.

Deficitul bugetar ar urma să fie în acest an de 5% din PIB, cum este el proiectat în legea bugetului. Dar, într-o lege identică, a fost proiectat la 4,4% din PIB, anul trecut. Aşa că sunt multe necunoscute. Nu ştim evoluţiile legate de noile măsuri fiscale intrate în vigoare la început de an, aşa că nici impactul lor asupra bugetului.

„Nu este de râs, dar nici de plâns. Situaţia din 2023 este identică aproape cu cea din 2022. Este important că economia zonei euro nu scade. Nu creşte exponenţial, dar nu scade. Dacă creşte cu 0,6%, cum arată prognozele, e o creştere. Nu creşte mult, dar nu scade. La fel în cazul României. Economia creşte. Cresc veniturile, cresc şi cheltuielile. Nu este nicio temere aici. Economiile îşi revin, asta arată toate datele”, spune Laurian Lungu.
`;
const controller = new AbortController();
const signal = controller.signal;
export const UserInputCard = ({ setResults }) => {
  const { open, setModalMessage } = useInfoModal();
  const [inputType, setInputType] = useState(INPUT_TYPE_TEXT);
  const [modelRunning, setModelRunning] = useState(false);
  const [modelInput, setModelInput] = useState(defaultInput);
  const { apiKey } = useAuth();
  const onRunModelClick = useCallback(() => {
    if (apiKey && modelInput && setResults) {
      let body =
        inputType === INPUT_TYPE_TEXT
          ? { text: modelInput }
          : { file: modelInput };
      body = { ...body, extractEntityRelation: false };
      const formData = new FormData();
      formData.append("apiKey", apiKey);
      if (body.text) {
        formData.append("text", body.text);
      }
      if (body.file) {
        formData.append("file", body.file);
      }
      formData.append("extractEntityRelation", body.extractEntityRelation);
      setModelRunning(true);
      callModel({
        formData,
        setResults,
        setModelRunning,
        controller,
        signal,
        modelNr: 1,
        openInfoModal: open,
        setModalMessage,
      });
    }
  }, [
    apiKey,
    inputType,
    modelInput,
    setModelRunning,
    setResults,
    open,
    setModalMessage,
  ]);

  return (
    <Paper withBorder radius="md">
      <Stack justify="flex-start" h="100%" gap={0}>
        <Group justify="space-between" p="2rem" gap="6rem">
          <Radio.Group size="1.2rem" value={inputType} onChange={setInputType}>
            <Group gap="3.5rem">
              <Radio
                style={{ caretColor: "transparent" }}
                value="text"
                label="Text"
                c="#403F3F"
                icon={CheckIcon}
              ></Radio>
              <Radio
                style={{ caretColor: "transparent" }}
                value="file"
                label="File"
                c="#403F3F"
                icon={CheckIcon}
              ></Radio>
            </Group>
          </Radio.Group>
          <RunModelControls
            modelRunning={modelRunning}
            onRunModelClick={onRunModelClick}
            modelInput={modelInput}
          />
        </Group>

        {inputType === INPUT_TYPE_TEXT && (
          <NerInputText
            setModelInput={setModelInput}
            currentModelInput={modelInput}
          />
        )}
        {inputType === INPUT_TYPE_FILE && (
          <NerInputFile
            setModelInput={setModelInput}
            currentModelInput={modelInput}
          />
        )}
      </Stack>
    </Paper>
  );
};
