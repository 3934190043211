import { Center, Group, ScrollArea, Select } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { EntityRelationsGraph } from "./Graph";
import { getEntityRelations } from "./helpers";

const firstEndOfRelationPosition = 0;
const seconEndOfRelationPosition = 2;
const relationPosition = 1;
const getEntities = (relations) => {
  const entities = new Set();
  relations.forEach((elem) => {
    if (elem[firstEndOfRelationPosition] !== "")
      entities.add(removePunctuation(elem[firstEndOfRelationPosition]));
  });
  return Array.from(entities);
};
const removePunctuation = (text) => {
  const punctuation = /[^a-zA-Z0-9]/g;
  return text.replace(punctuation, "");
};

const getAllRelationsForEntity = (entity, entityRelations) => {
  const results = [];
  if (entity === "") return undefined;

  entityRelations.forEach((entityRel) => {
    const relationRoot = removePunctuation(
      entityRel[firstEndOfRelationPosition]
    );
    if (relationRoot !== "" && relationRoot === entity) {
      const newRelation = {
        entity: entityRel[seconEndOfRelationPosition],
        relation: entityRel[relationPosition],
      };
      results.push(newRelation);
    }
  });

  return results;
};

const createGraphForRootEntity = (rootEntity, entityRelations) => {
  const rootNodeId = 1;
  let currentEdgeId = 1;
  let currentNodeId = rootNodeId + 1;
  const rootNode = {
    id: rootNodeId,
    label: rootEntity,
    title: rootEntity,
  };
  const nodes = [rootNode];
  const edges = [];
  const relationsForEntity = getAllRelationsForEntity(
    rootEntity,
    entityRelations
  );
  const labels = new Array(relationsForEntity?.length).fill(0);
  relationsForEntity?.forEach((relation) => {
    const newNode = {
      id: currentNodeId,
      label: relation.entity,
      title: relation.entity,
    };
    const newEdge = {
      id: currentEdgeId,
      from: rootNodeId,
      to: currentNodeId,
    };
    labels[currentEdgeId - 1] = relation.relation;
    nodes.push(newNode);
    edges.push(newEdge);
    currentEdgeId++;
    currentNodeId++;
  });
  return {
    nodes,
    edges,
    labels,
  };
};

export const GraphFormatResults = ({ results }) => {
  const [entityRelations, setEntityRelations] = useState();
  const [rootEntity, setRootEntity] = useState();
  const [entities, setEntities] = useState();
  const [graph, setGraph] = useState();
  useEffect(() => {
    if (results) {
      const currentEntityRelations = getEntityRelations(results);
      const currentEntities = getEntities(currentEntityRelations);
      setGraph({
        ...createGraphForRootEntity(currentEntities[0], currentEntityRelations),
      });
      setEntityRelations(currentEntityRelations);
      setEntities(currentEntities);
      setRootEntity(currentEntities[0]);
    }
  }, [results]);
  useEffect(() => {
    if (rootEntity && entityRelations) {
      setGraph({
        ...createGraphForRootEntity(rootEntity, entityRelations),
      });
    }
  }, [rootEntity, entityRelations]);

  const onRootEntitySelected = (event) => {
    setRootEntity(event.target.value);
  };

  return (
    <Center>
      <Group gap={0} justify="center">
        <Select
          mt="xl"
          label="Select root entity"
          placeholder="Pick a value"
          data={entities}
          defaultValue={entities ? entities[0] : null}
          value={rootEntity ? rootEntity : entities ? entities[0] : null}
          allowDeselect={false}
          onSelect={onRootEntitySelected}
        />

        <EntityRelationsGraph graph={graph} />
      </Group>
    </Center>
  );
};
