import { Title } from "@mantine/core";

const defaultValue = "Model description";
export const TitleModelDescription = ({ title }) => {
  return (
    <Title order={3} ml="1rem" mt="0.5rem" p={5} mr="0">
      {title ? title : defaultValue}
    </Title>
  );
};
