import { useState } from "react";
import {
  Group,
  Box,
  Collapse,
  ThemeIcon,
  Text,
  UnstyledButton,
  Flex,
} from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import "./LinksGroup.scss";
import { useNavigate } from "react-router-dom";

export const LinksGroup = ({
  icon: Icon,
  label,
  initiallyOpened,
  links,
  active,
}) => {
  const hasLinks = Array.isArray(links);
  const [opened, setOpened] = useState(initiallyOpened || false);
  const history = useNavigate();
  const items = (hasLinks ? links : []).map((link) => (
    <Text
      component="a"
      className="link"
      href={link.link}
      key={link.label}
      data-active={link.label === active || undefined}
      onClick={(event) => {
        event.preventDefault();
        history(link.link);
      }}
    >
      {link.label}
    </Text>
  ));

  return (
    <>
      <UnstyledButton
        onClick={() => {
          setOpened((o) => !o);
        }}
        className="control"
        data-active={true}
      >
        <Group justify="space-between" gap={0}>
          <Flex align="center">
            <ThemeIcon variant="light" size={30}>
              <Icon className="container--menu-icon" />
            </ThemeIcon>
            <Box ml="md">{label}</Box>
          </Flex>
          {hasLinks && (
            <IconChevronRight
              stroke={1.5}
              className={
                opened
                  ? "container--chevron-icon-rotated"
                  : "container--chevron-icon"
              }
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  );
};
