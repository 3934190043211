import { Divider, Group, Stack, Text } from "@mantine/core";
import "../ModelsPages.scss";
import { prepareResultsForDisplay } from "./helpers";

export const TextFormatResults = ({ results }) => {
  return (
    <Stack mt="xl" p="1rem">
      <Group justify="space-between">
        <Text c="dimmed">CATEGORY</Text>
        <Text c="dimmed">CONFIDENCE</Text>
      </Group>
      <Divider />
      {results &&
        prepareResultsForDisplay(results)?.map((result) => (
          <>
            <Group justify="space-between">
              <Text>{result.category}</Text>
              <Text c="var(--mantine-color-blue-filled)">
                {result.confidence}
              </Text>
            </Group>
            <Divider />
          </>
        ))}
    </Stack>
  );
};
