import {
  Group,
  NumberInput,
  Slider,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { IconQuestionMark } from "@tabler/icons-react";
import { useCallback, useState } from "react";
import { RunModelControls } from "../RunModelControls";
import { UserInputWrapper } from "../UserInputWrapper";
import { useAuth } from "../../../AuthenticationProvider";
import { callModel } from "../../helpers";
import { useInfoModal } from "../../../InfoModalProvider";

const controller = new AbortController();
const signal = controller.signal;
const defaultValue =
  "Please describe in a few words the most important facts about Angelina Jolie";
const defaultTemperature = 0.2;
const defaultMaximumWordsGenerated = 210;
export const UserInputCard = ({ setResults }) => {
  const { open, setModalMessage } = useInfoModal();
  const [modelInput, setModelInput] = useState(defaultValue);
  const [generatedTextSize, setGeneratedTextSize] = useState(
    defaultMaximumWordsGenerated
  );
  const [temperature, setTemperature] = useState(defaultTemperature);
  const [modelRunning, setModelRunning] = useState(false);
  const { apiKey } = useAuth();
  const onModelInputChange = (event) => {
    setModelInput(event.target.value);
  };
  const onRunModelClick = useCallback(() => {
    if (
      apiKey &&
      setResults &&
      modelInput &&
      temperature &&
      generatedTextSize
    ) {
      const body = {
        temperature,
        generatedTextSize,
        message: modelInput,
      };
      setModelRunning(true);
      const formData = new FormData();
      formData.append("apiKey", apiKey);
      formData.append("generatedTextSize", body.generatedTextSize);
      formData.append("message", body.message);
      formData.append("temperature", body.temperature);
      callModel({
        setModelRunning,
        setResults,
        signal,
        controller,
        modelNr: 6,
        formData,
        openInfoModal: open,
        setModalMessage,
      });
    }
  }, [
    apiKey,
    setModelRunning,
    setResults,
    temperature,
    generatedTextSize,
    modelInput,
    open,
    setModalMessage,
  ]);
  return (
    <UserInputWrapper>
      <Group
        align="flex-start"
        justify="space-between"
        p="2rem"
        gap="6rem"
        pb="0.5rem"
      >
        <Stack>
          <TextInput
            leftSectionPointerEvents="none"
            leftSection={
              <IconQuestionMark
                size={17}
                color="var(--mantine-color-blue-filled)"
              />
            }
            description="Message for the Text generator model"
            placeholder="Write your text here"
            w="35vw"
            styles={{
              description: { fontWeight: "700" },
              input: { borderColor: "transparent" },
            }}
            onChange={(event) => onModelInputChange(event)}
            defaultValue={defaultValue}
            // TODO:  defaultValue={defvalue}
          />
          <NumberInput
            description="Generated text maximum size"
            placeholder="Value between 200 and 500"
            min={200}
            max={500}
            w="15rem"
            styles={{
              description: { fontWeight: 700 },
              input: {
                color: "var(--mantine-color-blue-filled)",
              },
            }}
            onChange={(event) => setGeneratedTextSize(event)}
            defaultValue={defaultMaximumWordsGenerated}
          />
          <Text size="xs" c="dimmed">
            <b>Temperature</b>
          </Text>
          <Slider
            w="15rem"
            color="blue"
            label={(value) => `${value / 100}`}
            onChange={(value) => setTemperature(value / 100)}
            defaultValue={50}
          />
        </Stack>
        <RunModelControls
          modelRunning={modelRunning}
          modelInput={modelInput}
          onRunModelClick={onRunModelClick}
        />
      </Group>
    </UserInputWrapper>
  );
};
