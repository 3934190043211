import { LoadingOverlay, Box } from "@mantine/core";

export const Loading = () => {
  return (
    <>
      <LoadingOverlay
        visible={true}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
    </>
  );
};
