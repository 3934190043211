import { ScrollArea, Divider, Menu, Code, Stack } from "@mantine/core";
import {
  IconLogout,
  IconUser,
  IconTerminal2,
  IconClipboardText,
} from "@tabler/icons-react";
import { Logo } from "../Logo/Logo";
import "./Menu.scss";
import { LinksGroup } from "../LinksGroup/LinksGroup";
import { UserButton } from "../UserButton/UserButton";
import { signOut } from "firebase/auth";
import { firebaseAuth } from "../../../firebase/firebaseInit";
import { JWT_TOKEN_KEY } from "../../../utils/constants";
import { notifications } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
const mockdata = [
  {
    label: "Account",
    icon: IconUser,
    initiallyOpened: true,
    links: [
      { label: "API keys", link: "/api-keys" },
      { label: "Subscription", link: "/subscription" },
    ],
  },
  {
    label: "Demo playground",
    icon: IconTerminal2,
    initiallyOpened: true,
    links: [
      { label: "Named Entity Recognition", link: "/ner" },
      {
        label: "Entity relations extraction",
        link: "/entity-relations",
      },
      { label: "Text classification", link: "/text-classification" },
      { label: "Text QA", link: "/text-qa" },
      { label: "Text summarization", link: "/text-summarization" },
      { label: "Open domain QA", link: "/open-domain-qa" },
      { label: "Text generator", link: "/text-generator" },
    ],
  },
  {
    label: "Api documentation",
    icon: IconClipboardText,
    links: [{ label: "Getting started", link: "/getting-started" }],
  },
];

export const HomeMenu = ({ setActivePage, selected }) => {
  const navigate = useNavigate();
  const onLogout = () => {
    signOut(firebaseAuth)
      .then((resp) => {
        sessionStorage.removeItem(JWT_TOKEN_KEY);
        navigate("/login");
      })
      .catch((err) =>
        notifications.show({
          loading: false,
          title: "There has been a problem signing you out!",
          message: "Please try again",
          autoClose: 3000,
          color: "red",
        })
      );
  };
  const links = mockdata.map((item) => (
    <LinksGroup {...item} key={item.label} active={selected} />
  ));

  return (
    <nav className="menu-navbar">
      <div className="menu-header">
        <Stack justify="center" align="center" gap={0}>
          <Logo />
          <Code fw={700} color="#F1F1F1">
            Trial account
          </Code>
        </Stack>
      </div>

      <ScrollArea className="menu-links">
        <div className="menu-linksInner">{links}</div>
      </ScrollArea>
      <Divider />
      <div className="menu-footer">
        <Menu
          position="right"
          withArrow
          arrowPosition="center"
          shadow="md"
          width="15rem"
          offset={8}
        >
          <Menu.Target>
            <div>
              <UserButton />
            </div>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              className="menu-option"
              leftSection={<IconLogout className="container--icon-logout" />}
              onClick={() => onLogout()}
            >
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
    </nav>
  );
};
