import { Center, Image, Modal, Stack, Text } from "@mantine/core";
import { useInfoModal } from "../../InfoModalProvider";
import warningImage from "../../../assets/warning-64.png";

export const CenteredModal = () => {
  const { opened, close, modalMessage } = useInfoModal();
  return (
    <Modal opened={opened} onClose={close} withCloseButton={false} centered>
      <Stack>
        <Center>
          <Image fit="contain" src={warningImage} w="5rem" h="5rem" />
        </Center>
        <Text>{modalMessage}</Text>
      </Stack>
    </Modal>
  );
};
