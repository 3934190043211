import { Flex, Image, Text } from "@mantine/core";
import "./Logo.scss";

export const Logo = () => {
  return (
    <Flex gap="xs">
      <Image
        w="10rem"
        fit="contain"
        src={require("../../../assets/ontegra-logo.png")}
        className="logo__home--selection"
      />
      <Text className="text__ai" mb={24}>
        AI
      </Text>
    </Flex>
  );
};
