import { Center, Group, ScrollArea, Space, Text } from "@mantine/core";
import { getEntityRelations } from "./helpers";

export const TextFormatResults = ({ results }) => {
  return (
    <Center p="xs" mt="xl" ml="xs">
      <ScrollArea h="70vh" w="95%">
        <Group justify="center">
          {results &&
            getEntityRelations(results)?.map((entitiesRelation) => {
              return (
                <>
                  {" "}
                  <Text>
                    <span className="wrapper--span-blue-filled">
                      {entitiesRelation[0]} |
                    </span>{" "}
                    {entitiesRelation[1]}{" "}
                    <span className="wrapper--span-blue-filled">
                      | {entitiesRelation[2]}
                    </span>
                  </Text>{" "}
                  <Space h="xl" />
                </>
              );
            })}
        </Group>
      </ScrollArea>
    </Center>
  );
};
