import { PasswordInput } from "@mantine/core";
import { PasswordInputWithStrengthMeter } from "./PasswordInputWithStrengthMeter";

export const PasswordsContainer = ({
  setPassword,
  setConfirmPassword,
  isPasswordValid,
  passwordsMatch,
}) => {
  return (
    <>
      {isPasswordValid ? (
        <PasswordInputWithStrengthMeter
          label={"Password"}
          placeholder={"Password"}
          mt={"lg"}
          size={"xl"}
          onChange={setPassword}
        />
      ) : (
        <PasswordInput
          label="Password"
          placeholder={
            !passwordsMatch
              ? "Passwords do not match"
              : !isPasswordValid && "Invalid password"
          }
          mt="lg"
          size="xl"
          error
          onChange={(event) => setPassword(event, true)}
          withAsterisk
        />
      )}
      {passwordsMatch ? (
        <PasswordInput
          label="Confirm password"
          placeholder="Confirm password"
          mt="lg"
          size="xl"
          onChange={setConfirmPassword}
          withAsterisk
        />
      ) : (
        <PasswordInput
          label="Confirm password"
          placeholder={
            !passwordsMatch
              ? "Passwords do not match"
              : !isPasswordValid && "Invalid password"
          }
          mt="lg"
          size="xl"
          error
          onChange={(event) => setPassword(event, true)}
          withAsterisk
        />
      )}
    </>
  );
};
