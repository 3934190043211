import { useEffect, useState } from "react";
import { GeneralModelResultsCard } from "../GeneralModelResultsCard";
import { TextFormatResults } from "./TextFormatResults";
import { parseTextSummarizationResponseForTextDisplay } from "./helper";

export const ModelResultsCard = ({ results }) => {
  const [resultDisplayed, setResultDisplayed] = useState(false);
  const [resultTextFormat, setResultTextFormat] = useState();
  useEffect(() => {
    setResultDisplayed(false);
    if (results) {
      setResultTextFormat(
        parseTextSummarizationResponseForTextDisplay(results)?.summary
      );
    }
  }, [results]);
  return (
    <GeneralModelResultsCard
      results={results}
      textFormatResultsComponent={
        <TextFormatResults
          result={resultTextFormat}
          resultDisplayed={resultDisplayed}
          setResultDisplayed={setResultDisplayed}
        />
      }
    />
  );
};
