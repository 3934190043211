import { Group, Paper, Stack, TagsInput } from "@mantine/core";
import { useCallback, useState } from "react";
import { useAuth } from "../../../AuthenticationProvider";
import { NerInputText } from "../Ner/NerInputText";
import { RunModelControls } from "../RunModelControls";
import { callModel } from "../../helpers";
import { useInfoModal } from "../../../InfoModalProvider";
const defaultText = `
Just getting to the end of the day and surviving the night must feel like a miracle in the Gaza Strip. Palestinians "plead for safety", wrote Philippe Lazzarini, head of UNRWA, the main UN relief agency in Gaza, in an "endless, deepening tragedy… hell on earth".

It must be just as hellish for the hostages taken by Hamas and for the families of their victims. War is a cruel furnace that puts humans through terrible agonies. But its heat can produce changes that seemed impossible.

It happened in western Europe after World War Two. Old enemies who had killed each other for centuries chose peace. Will the war in Gaza shock Israelis and Palestinians into ending their century of conflict over the land between the Mediterranean Sea and the Jordan river?
`;
const defaultCategories = ["Gaza war", "sports news", "money", "great fear"];
const controller = new AbortController();
const signal = controller.signal;
export const UserInputCard = ({ setResults }) => {
  const { open, setModalMessage } = useInfoModal();
  const [modelRunning, setModelRunning] = useState(false);
  const [modelInput, setModelInput] = useState(defaultText);
  const [categories, setCategories] = useState(defaultCategories);
  const { apiKey } = useAuth();
  const onCategoriesChange = (values) => {
    setCategories(values);
  };
  const onRunModelClick = useCallback(() => {
    if (modelInput && categories && apiKey && setResults) {
      const body = {
        text: modelInput,
        categories,
      };
      setModelRunning(true);
      const formData = new FormData();
      formData.append("apiKey", apiKey);
      formData.append("text", body.text);
      formData.append("categories", body.categories);
      callModel({
        formData,
        setResults,
        setModelRunning,
        controller,
        signal,
        modelNr: 2,
        openInfoModal: open,
        setModalMessage,
      });
    }
  }, [modelInput, categories, apiKey, setResults, open, setModalMessage]);

  return (
    <Paper withBorder radius="md" w="100%">
      <Stack justify="flex-start" h="100%" gap={0}>
        <Group justify="space-between" align="center" p="2rem">
          <div>
            <TagsInput
              styles={{
                pill: {
                  backgroundColor: "var(--mantine-color-blue-filled)",
                  color: "white",
                },
                input: { borderColor: "transparent", marginLeft: "-0.8rem" },
                description: { fontWeight: "700" },
              }}
              w="45vw"
              description="Add up to 5 categories"
              placeholder="Enter category"
              maxTags={5}
              defaultValue={defaultCategories}
              onChange={(value) => onCategoriesChange(value)}
            />
          </div>
          <RunModelControls
            modelRunning={modelRunning}
            onRunModelClick={onRunModelClick}
            modelInput={modelInput}
          />
        </Group>
        <NerInputText
          setModelInput={setModelInput}
          currentModelInput={modelInput}
        />
      </Stack>
    </Paper>
  );
};
