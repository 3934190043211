import {
  Group,
  ScrollArea,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { prepareResultsForDisplay } from "./helpers";

export const TextFormatResults = ({ results }) => {
  return (
    <ScrollArea>
      <Stack h="78vh" mt="2rem" gap="xl">
        {results &&
          prepareResultsForDisplay(results).map((result) => (
            <Group p="1rem">
              <Group w="3vw">
                <Title order={1} c="#EAEAEA">
                  {result.score}
                </Title>
              </Group>
              <Stack gap={0} w="26vw">
                <Title order={4}>{result.title}</Title>
                <Text size="md">{result.text}</Text>
              </Stack>
            </Group>
          ))}
      </Stack>
    </ScrollArea>
  );
};
