import { Title } from "@mantine/core";
import "../LandingPage.scss";

export const PageTitle = ({ title }) => {
  return (
    <div className="container--page-title">
      <Title p={1} ml="2rem" order={2}>
        {title}
      </Title>
    </div>
  );
};
