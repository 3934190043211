import { TextInput, Text, Popover } from "@mantine/core";
import { useState } from "react";

export const EmailInputContainer = ({
  isCompanyEmailValid,
  onEmailChange,
}) => {
  const [popoverOpened, setPopoverOpened] = useState(false);
  return (
    <>
      <Popover
        opened={popoverOpened}
        position="top"
        width="target"
        withArrow
        transitionProps={{ transition: "pop" }}
        arrowSize={15}
      >
        <Popover.Target>
          <div
            onFocusCapture={() => setPopoverOpened(true)}
            onBlurCapture={() => setPopoverOpened(false)}
          >
            {isCompanyEmailValid ? (
              <TextInput
                label="Company email"
                placeholder="name@company.com"
                mt="lg"
                size="xl"
                onChange={onEmailChange}
                withAsterisk
              />
            ) : (
              <TextInput
                label="Company email"
                placeholder="Invalid company email"
                mt="lg"
                size="xl"
                error
                onChange={(event) => onEmailChange(event, true)}
                withAsterisk
              />
            )}
          </div>
        </Popover.Target>
        <Popover.Dropdown>
          <Text>
            Please use your company email here, personal emails won't be
            accepted.
            <br></br>
            Examples:<br></br>myname@mycompany.com (valid)
            <br></br>
            myname@gmail.com (not valid)
          </Text>
        </Popover.Dropdown>
      </Popover>
    </>
  );
};
