import { ModelPageRootWrapper } from "../ModelPageRootWrapper";
import { useEffect, useState } from "react";
import { UserInputCard } from "./UserInputCard";
import { ModelDescriptionCard } from "./ModelDescriptionCard";
import { ModelResultsCard } from "./ModelResultsCard";
import { HomeMenu } from "../../Menu/HomeMenu";
import { Flex } from "@mantine/core";
import { useAuth } from "../../../AuthenticationProvider";
import { getUserAccount, homePageOptions } from "../../helpers";

export const TextSummarizationPage = () => {
  const { currentUserEmail, setApiKey, setCurrentUserName, apiKey } = useAuth();
  useEffect(() => {
    if (!apiKey) {
      if (currentUserEmail && setApiKey && setCurrentUserName) {
        getUserAccount(currentUserEmail)
          .then((resp) => resp.data)
          .then(({ userApiKey, fullName }) => {
            setApiKey(userApiKey);
            setCurrentUserName(fullName);
          });
      }
    }
  }, [currentUserEmail, setApiKey, setCurrentUserName, apiKey]);
  const [results, setResults] = useState();
  return (
    <Flex>
      <HomeMenu selected={homePageOptions.PLAYGROUND_TEXT_SUMMARIZATION} />{" "}
      <ModelPageRootWrapper
        pageTitle={homePageOptions.PLAYGROUND_TEXT_SUMMARIZATION}
        inputAndModelDescriptionChild={
          <>
            <UserInputCard setResults={setResults} />
            <ModelDescriptionCard />
          </>
        }
        modelResultsCardChild={<ModelResultsCard results={results} />}
      />
    </Flex>
  );
};
