import { Group, Paper, Stack, TextInput } from "@mantine/core";
import { IconQuestionMark } from "@tabler/icons-react";
import { useCallback, useState } from "react";
import { useAuth } from "../../../AuthenticationProvider";
import { RunModelControls } from "../RunModelControls";
import { callModel } from "../../helpers";
import { useInfoModal } from "../../../InfoModalProvider";

const controller = new AbortController();
const signal = controller.signal;
const defaultQuestionValue = `When did the revolution take place in Romania?`;
export const UserInputCard = ({ setResults }) => {
  const { open, setModalMessage } = useInfoModal();
  const [modelRunning, setModelRunning] = useState(false);
  const [question, setQuestion] = useState(defaultQuestionValue);
  const { apiKey } = useAuth();
  const onQuestionChange = (event) => {
    setQuestion(event.target.value);
  };
  const onRunModelClick = useCallback(() => {
    if (apiKey && setResults && question) {
      const body = {
        question: question,
      };
      const formData = new FormData();
      formData.append("apiKey", apiKey);
      formData.append("question", body.question);
      setModelRunning(true);
      callModel({
        setModelRunning,
        setResults,
        signal,
        controller,
        formData,
        modelNr: 5,
        setModalMessage,
        openInfoModal: open,
      });
    }
  }, [apiKey, setModelRunning, setResults, question, open, setModalMessage]);

  return (
    <Paper withBorder radius="md" h="8rem">
      <Stack justify="flex-start" h="100%" gap={0}>
        <Group
          align="flex-end"
          justify="space-between"
          p="2rem"
          gap="6rem"
          pb="0.5rem"
        >
          <Group>
            <TextInput
              leftSectionPointerEvents="none"
              leftSection={
                <IconQuestionMark
                  size={17}
                  color="var(--mantine-color-blue-filled)"
                />
              }
              description="Question"
              placeholder="Question on Wikipedia Topic"
              w="35vw"
              styles={{
                description: { fontWeight: "700" },
                input: { borderColor: "transparent" },
              }}
              onChange={(event) => onQuestionChange(event)}
              defaultValue={defaultQuestionValue}
            />
          </Group>
          <RunModelControls
            modelRunning={modelRunning}
            onRunModelClick={onRunModelClick}
            modelInput={question}
          />
        </Group>
      </Stack>
    </Paper>
  );
};
