import { GeneralModelResultsCard } from "../GeneralModelResultsCard";
import { TextFormatResults } from "./TextFormatResults";

export const ModelResultsCard = ({ results }) => {
  return (
    <GeneralModelResultsCard
      results={results}
      textFormatResultsComponent={<TextFormatResults results={results} />}
    />
  );
};
