import { Flex, Group, Pill, PillsInput, Space, Text } from "@mantine/core";
import { ModelDescriptionContainer } from "../ModelDescriptionContainer";
import {
  IconNumber1,
  IconNumber2,
  IconNumber3,
  IconNumber4,
} from "@tabler/icons-react";
import { TimelineContainer } from "../TimelineContainer";
import "../ModelsPages.scss";
import { NerEntityPill } from "../Ner/NerEntityPill";

const DEMO_RELATIONS = {
  no_relation: "no relation found",
  "org:alternate_names": "an organization's alternate names",
  "org:city_of_headquarters": "an organization's city of headquarters",
  "org:country_of_headquarters": "an organization's country of headquarters",
  "org:dissolved": "an organization's dissolved",
  "org:founded": "an organization's founded date",
  "org:founded_by": "an organization's founded by",
  "org:member_of": "an organization's member",
  "org:members": "an organization's members",
  "org:number_of_employees/members": "an organization's number of employees",
  "org:parents": "an organization's parents",
  "org:political/religious_affiliation": "an organization's religious affiliation",
  "org:shareholders": "an organization's shareholders",
  "org:stateorprovince_of_headquarters": "an organization's state or province headquarters",
  "org:subsidiaries": "an organization's subsidiaries",
  "org:top_members/employees": "an organization's top employees/members",
  "org:website": "an organization's website",
  "per:age": "a person's age",
  "per:alternate_names": "a person's alternate names",
  "per:cause_of_death": "a person's cause of death",
  "per:charges": "a person's charges",
  "per:children": "a person's children",
  "per:cities_of_residence": "a person's city of residence",
  "per:city_of_birth": "a person's city of birth",
  "per:city_of_death": "a person's city of death",
  "per:countries_of_residence": "a person's country of residence",
  "per:country_of_birth": "a person's country of birth",
  "per:country_of_death": "a person's country of death",
  "per:date_of_birth": "a person's date of birth",
  "per:date_of_death": "a person's date of death",
  "per:employee_of": "a person is an employee where",
  "per:origin": "a person's origin",
  "per:other_family": "a person's family",
  "per:parents": "a person's parents",
  "per:religion": "a person's religion",
  "per:schools_attended": "a person's schools attended",
  "per:siblings": "a person's siblings",
  "per:spouse": "a person's spouse",
  "per:stateorprovince_of_birth": "a person's stateorprovince_of_birth",
  "per:stateorprovince_of_death": "a person's stateorprovince_of_death",
  "per:stateorprovinces_of_residence": "a person's stateorprovinces_of_residence",
  "per:title": "a person's title",
};
const timelineItems = [
  {
    title: "Input type and constraints",
    bullet: <IconNumber1 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        The input of the model is of type text or file
        (pdf,doc,docx,jpg,png,txt) and it represents the text from which the
        entitiy relations will be extracted.
        <Space />
        For demo version try to limitate to 1 page of text input size.
      </Text>
    ),
  },
  {
    title: "Select output format",
    bullet: <IconNumber2 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        You can choose in which format to view the response of the model, text,
        JSON or Graph (visual).
      </Text>
    ),
  },
  {
    title: "Run the model",
    bullet: <IconNumber3 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        To start the model processing simply press the green terminal button
        ("Run model").
      </Text>
    ),
  },
  {
    title: "Analyzing model results",
    bullet: <IconNumber4 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        The result of the model consists of a list where each element represents
        a relation.
        A relation is composed of the entities pair that are in a relation and the relation name.
        <Space />
      </Text>
    ),
  },
];
export const ModelDescriptionCard = () => {
  return (
    <ModelDescriptionContainer
      introduction={
        <Text>
          The{" "}
          <span className="wrapper--span-blue-filled">
            Entity Relations Extraction
          </span>{" "}
          model is trained to extract relations between entities found in text.
          <Space h="xs" />
          The two <b>main challenges</b> of Entity Relations Extraction are
          identifying the entities and the relations between entities.
          <Space h="xs" />
          This demo supports only the extraction of the following relations:{" "}
          <Space h="xs" />
          <Flex
            justify="flex-start"
            align="flex-start"
            direction="row"
            wrap="wrap"
            w="800px"
          >
            <PillsInput variant="unstyled" w="100%" mt="1rem">
              <Pill.Group>
                {Object.entries(DEMO_RELATIONS).map(
                  ([relation, description]) => (
                    <NerEntityPill
                      description={relation}
                      tooltip={description}
                    />
                  )
                )}
              </Pill.Group>
            </PillsInput>
          </Flex>
          <Space h="xs" />
        </Text>
      }
      languages={
        <Text>
          For custom solutions we support almost{" "}
          <b className="wrapper--span-blue-filled">any language</b>
          .
          <Space />
          The Trial Entity Relations Extraction model is limited to the{" "}
          <b>English </b>
          language.
        </Text>
      }
      quickStart={<TimelineContainer timelineItems={timelineItems} />}
    ></ModelDescriptionContainer>
  );
};
