import axios from "axios";
import { JWT_TOKEN_KEY } from "../../utils/constants";

export const verifyToken = async () => {
  return axios
    .post(
      `https://identitytoolkit.googleapis.com/v1/accounts:lookup?key=${process.env.REACT_APP_FIREBASE_APP_KEY}`,
      {
        idToken: sessionStorage.getItem(JWT_TOKEN_KEY),
      }
    )
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return false;
    });
};
