import axios from "axios";
import { JWT_TOKEN_KEY } from "../../utils/constants";
import { fetchEventSource } from "@microsoft/fetch-event-source";

export const homePageOptions = {
  ACCOUNT_API_KEYS: "API keys",
  ACCOUNT_SUBSCRIPTION: "Subscription",
  PLAYGROUND_NER: "Named Entity Recognition",
  PLAYGROUND_ENTITY_RELATIONS: "Entity relations extraction",
  PLAYGROUND_TEXT_CLASSIFICATION: "Text classification",
  PLAYGROUND_TEXT_QA: "Text QA",
  PLAYGROUND_TEXT_SUMMARIZATION: "Text summarization",
  PLAYGROUND_OPEN_DOMAIN_QA: "Open domain QA",
  PLAYGROUND_TEXT_GENERATOR: "Text generator",
  DOCUMENTATION_GETTING_STARTED: "Getting started",
};
export const getUserAccount = (email) => {
  const headers = {
    "WEB-NLP-API-KEY": process.env.REACT_APP_WEB_NLP_API_KEY,
    Authorization: `Bearer ${sessionStorage.getItem(JWT_TOKEN_KEY)}`,
  };
  return axios.get(
    `web-nlp-api/user/login/${email}`,
    {
      headers,
    }
  );
};

export const callModel = async ({
  setResults,
  setModelRunning,
  signal,
  controller,
  formData,
  modelNr,
  openInfoModal,
  setModalMessage,
}) => {
  return fetchEventSource(
    `web-nlp-api/model/${modelNr}`,
    {
      method: "POST",
      signal,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem(JWT_TOKEN_KEY)}`,
        "WEB-NLP-API-KEY": process.env.REACT_APP_WEB_NLP_API_KEY,
      },
      body: formData,
      onopen(res) {
        if (res.ok && res.status === 200) {
          //connection made
        } else if (
          res.status >= 400 &&
          res.status < 500 &&
          res.status !== 429
        ) {
          //client side error
        }
      },
      onmessage(event) {
        if (event.data !== "ping") {
          const parsedData = JSON.parse(event.data);
          //TO DO: when response will be received in streams should set setData((data) => [...data, parsedData]);
          if (parsedData.statusCodeValue === 400) {
            setModalMessage(parsedData.body);
            openInfoModal();
          } else {
            setResults({ ...parsedData });
          }
          controller.abort();
          setModelRunning(false);
        }
      },
      onclose() {
        //connection closed by server
      },
      onerror(err) {
        //error from server
      },
    }
  );
};
