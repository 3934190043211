import { Group, ScrollArea } from "@mantine/core";
import {
  JsonView,
  collapseAllNested,
  defaultStyles,
} from "react-json-view-lite";
import "./NerJsonResults.scss";

export const NerJsonResults = ({ results }) => {
  return (
    <Group justify="flex-start" mt="3rem" ml="2.7rem">
      <ScrollArea h="70vh" w="50vw">
        <JsonView
          data={results}
          shouldExpandNode={collapseAllNested}
          style={defaultStyles}
        ></JsonView>
      </ScrollArea>
    </Group>
  );
};
