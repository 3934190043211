import axios from "axios";
import { getPasswordStrength } from "./PasswordInputWithStrengthMeter";

export const INVALID_PASSWORD = "Invalid password";
export const PASSWORDS_DO_NOT_MATCH = "Passwords do not match";
export const VALID_PASSWORDS = "Valid passwords";

export const signupOnServerSide = (body) => {
  return axios.post(
    `web-nlp-api/user/signup`,
    { ...body },
    {
      headers: {
        "WEB-NLP-API-KEY": process.env.REACT_APP_WEB_NLP_API_KEY,
      },
    }
  );
};

export const validateCompanyEmail = (email) => {
  if (!email) return false;
  email = email.toLowerCase();
  const isEmailFormat = /\S+@\S+\.\S+/.test(email);
  return (
    isEmailFormat &&
    !email.includes("yahoo") &&
    !email.includes("gmail") &&
    !email.includes("googlemail") &&
    !email.includes("outlook") &&
    !email.includes("icloud") &&
    !email.includes("hotmail")
  );
};

export const validateFirstName = (firstName) => {
  return firstName !== "";
};

export const validateLastName = (lastName) => {
  return lastName !== "";
};

export const validatePasswords = ({ password, confirmPassword }) => {
  if (!password || getPasswordStrength(password) !== 100)
    return INVALID_PASSWORD;
  if (password !== confirmPassword) return PASSWORDS_DO_NOT_MATCH;
  return VALID_PASSWORDS;
};

export const validatePhoneNumber = (phoneNr) => {
  return /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(phoneNr);
};
