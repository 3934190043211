import { Group, Space, Stack, Text } from "@mantine/core";
import { parseTextQaResponseForTextDisplay } from "./helpers";
import "../ModelsPages.scss";

export const TextFormatResults = ({ result }) => {
  return (
    <Stack mt="2rem">
      <Group p={25} justify="flex-start">
        <Text>
          <b className="wrapper--text-grey">Question answer:</b>
          <Space h="xs" />
          <Text c="dimmed">
            {parseTextQaResponseForTextDisplay(result)?.answer}
          </Text>
        </Text>
      </Group>
    </Stack>
  );
};
