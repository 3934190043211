import { Space, Text } from "@mantine/core";
import { ModelDescriptionContainer } from "../ModelDescriptionContainer";
import {
  IconNumber1,
  IconNumber2,
  IconNumber3,
  IconNumber4,
} from "@tabler/icons-react";
import { TimelineContainer } from "../TimelineContainer";
import "../ModelsPages.scss";

const timelineItems = [
  {
    title: "Input",
    bullet: <IconNumber1 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        The text generator model's input consists of 3 key parameters:
        <Space h="xs" />
        <Text ml="xs">
          <b>message</b> for the Text generator model
        </Text>
        <Space />
        <Text ml="xs">
          {" "}
          <b>generated text size</b> (the maximum number of words for the
          generated text)
        </Text>
        <Space />
        <Text ml="xs">
          <b>temperature</b>, a value between 0 and 1 that instructs the model
          how creative to be when generating text. A value closer to 1 means
          that the model will most likely generate text that is invented and it
          does not represent real facts while a value closer to 0 instructs the
          model to generate text that is in accordance with reality.
        </Text>
      </Text>
    ),
  },
  {
    title: "Select output format",
    bullet: <IconNumber2 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        You can choose in which format to view the response of the model, text
        or JSON.
      </Text>
    ),
  },
  {
    title: "Run the model",
    bullet: <IconNumber3 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        To start the model processing simply press the green terminal button
        ("Run model").
      </Text>
    ),
  },
  {
    title: "Model results",
    bullet: <IconNumber4 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        The result of the model consists of a generated text that will pe
        displayed in the "Results" section.
      </Text>
    ),
  },
];
export const ModelDescriptionCard = () => {
  return (
    <ModelDescriptionContainer
      introduction={
        <Text>
          The <span className="wrapper--span-blue-filled">Text generator</span>{" "}
          model is actually a Large Language Model (LLM) trained on an English
          corpus.
          <Space />
          The model's main responsability is to simply generate text based on
          the message you provided. It is usually used in Conversational AI and
          intelligent search engines.
          <Space />
          Our Text generator model as well as all the other models can be
          deployed <b>on cloud and on-premise (private cloud)</b>.
        </Text>
      }
      languages={
        <Text>
          The Text generator model supports only <b>English</b> language for
          now.
        </Text>
      }
      quickStart={<TimelineContainer timelineItems={timelineItems} />}
    />
  );
};
