import { Paper, Flex, ScrollArea, Space, Group, Text } from "@mantine/core";
import { TitleModelDescription } from "./TitleModelDescription";
import { IconLanguage, IconMap2, IconTargetArrow } from "@tabler/icons-react";
import "../LandingPage.scss";

export const ModelDescriptionContainer = ({
  descriptionTitle,
  introduction,
  example,
  languages,
  quickStart,
}) => {
  return (
    <Paper
      className="wrapper--background-color-white"
      radius="md"
      h="50vh"
      w="100%"
    >
      <TitleModelDescription
        title={descriptionTitle ? descriptionTitle : undefined}
      />
      <Flex>
        <ScrollArea h="44vh" w="95%" type="hover" ml="2.5rem" mr="-20rem">
          <>{introduction}</>

          {example && (
            <>
              <Space h="xl" />
              <Group align="flex-start" gap="0.5rem">
                <IconTargetArrow color="var(--mantine-color-blue-filled)" />
                <Text fw={700} mb="1rem">
                  Example
                </Text>
              </Group>
              {example}
            </>
          )}

          <Space h="xl" />
          <Group align="flex-start" gap="0.5rem">
            <IconLanguage color="var(--mantine-color-blue-filled)" />
            <Text fw={700} mb="1rem">
              Languages
            </Text>
          </Group>
          <>{languages}</>

          <Space h="xl" />
          <Group align="flex-start" gap="0.5rem">
            <IconMap2 color="var(--mantine-color-blue-filled)" />
            <Text fw={700} mb="1rem">
              Quick start
            </Text>
          </Group>
          <>{quickStart}</>
        </ScrollArea>
      </Flex>
    </Paper>
  );
};
