import { Space, Text } from "@mantine/core";
import { ModelDescriptionContainer } from "../ModelDescriptionContainer";
import {
  IconNumber1,
  IconNumber2,
  IconNumber3,
  IconNumber4,
  IconNumber5,
} from "@tabler/icons-react";
import { TimelineContainer } from "../TimelineContainer";
import "../ModelsPages.scss";

const timelineItems = [
  {
    title: "Input type and constraints",
    bullet: <IconNumber1 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        For demo, the input is of type text and the maximum length allowed is
        equal to 1 page of text.
      </Text>
    ),
  },
  {
    title: "Select output format",
    bullet: <IconNumber2 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        You can choose in which format to display the results of the Text
        Summarization model processing (text or json).
      </Text>
    ),
  },
  {
    title: "Summary words count",
    bullet: <IconNumber3 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        For each summary you can set the <b>minimum</b> (between 40 and 149) and{" "}
        <b>maximum</b> (between 150 and 200) <b>words count</b>.
        <Space />
        This way you can instruct the model on how long the summarization should
        be.
      </Text>
    ),
  },
  {
    title: "Enter your text input",
    bullet: <IconNumber4 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        At this step you should enter the input text for summarization in the
        indicated field.
        <Space />
        To run the model simply press the green terminal button ("Run model").
      </Text>
    ),
  },
  {
    title: "Summarization results",
    bullet: <IconNumber5 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        You can view the Text Summarization model results in text and JSON
        format in the "Results" section.
      </Text>
    ),
  },
];
export const ModelDescriptionCard = () => {
  return (
    <ModelDescriptionContainer
      introduction={
        <Text>
          The{" "}
          <span className="wrapper--span-blue-filled">Text Summarization</span>{" "}
          model will help you take the key points of a written text and
          encapsulate them into a summary making the given text simpler to read
          and comprehend.
          <Space h="xs" />
          For <b>custom solutions</b> we offer Text Summarization models that
          can even summarize large ammounts of textual data contained in
          thousands of documents that can rapidly reduce the time taken for
          reading.
        </Text>
      }
      languages={
        <Text>
          For custom solutions we support up to{" "}
          <b className="wrapper--span-blue-filled">100 languages</b>
          .
          <Space />
          The Trial account text summarization model is limited to{" "}
          <b>English </b>
          language.
        </Text>
      }
      quickStart={<TimelineContainer timelineItems={timelineItems} />}
    ></ModelDescriptionContainer>
  );
};
