import { Timeline } from "@mantine/core";

export const TimelineContainer = ({ timelineItems }) => {
  return (
    <Timeline bulletSize={28} lineWidth={2}>
      {timelineItems.map((item) => {
        return (
          <Timeline.Item bullet={item.bullet} title={item.title}>
            {item.child}
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
};