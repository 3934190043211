import { useState } from "react";
import {
  Paper,
  Title,
  Group,
  Radio,
  Center,
  Image,
  CheckIcon,
  Text,
  Flex,
} from "@mantine/core";
import papersImage from "../../../assets/papers-grey.png";
import { NerJsonResults } from "./Ner/NerJsonResults";
import "../LandingPage.scss";

export const RESULT_JSON_FORMAT = "json";
export const RESULT_TEXT_FORMAT = "human-readable-text";
export const RESULT_GRAPH_FORMAT = "graph";
export const GeneralModelResultsCard = ({
  results,
  textFormatResultsComponent,
  withGraph = false,
  graphFormatResultsComponent,
}) => {
  const [resultFormat, setResultFormat] = useState(RESULT_TEXT_FORMAT);
  return (
    <Paper
      h="89vh"
      p={10}
      className="wrapper--model-results"
      mt="0.8rem"
      radius="md"
      mr="1rem"
      w="18vw"
    >
      <Title order={3} ml="1rem">
        Results
      </Title>
      <Group justify="center" mt="1.5rem" ml="1rem">
        <Radio.Group
          size="1.2rem"
          value={resultFormat}
          onChange={setResultFormat}
        >
          <Group gap="3.5rem">
            {withGraph && (
              <Radio
                className="caret-transparent"
                value={RESULT_GRAPH_FORMAT}
                label="Graph"
                c="#919191"
                icon={CheckIcon}
              ></Radio>
            )}
            <Radio
              className="caret-transparent"
              value={RESULT_TEXT_FORMAT}
              label="Text"
              c="#919191"
              icon={CheckIcon}
            ></Radio>
            <Radio
              className="caret-transparent"
              value={RESULT_JSON_FORMAT}
              label="JSON"
              c="#919191"
              icon={CheckIcon}
            ></Radio>
          </Group>
        </Radio.Group>
      </Group>
      {!results ? (
        <Center h="50vh" mt="2.5rem">
          <Flex direction="column" gap="1.5rem" justify="center" align="center">
            <Image
              className="transparent-caret"
              height={"195rem"}
              fit="contain"
              src={papersImage}
            />
            <Text order={6} c="#CCCCCC" fw={700} className="transparent-caret">
              No results to display
            </Text>
          </Flex>
        </Center>
      ) : (
        <>
          {resultFormat === RESULT_TEXT_FORMAT && (
            <>{textFormatResultsComponent}</>
          )}
          {resultFormat === RESULT_JSON_FORMAT && (
            <NerJsonResults results={results} />
          )}
          {resultFormat === RESULT_GRAPH_FORMAT && (
            <>{graphFormatResultsComponent}</>
          )}
        </>
      )}
    </Paper>
  );
};
