import { Space, Text } from "@mantine/core";
import { ModelDescriptionContainer } from "../ModelDescriptionContainer";
import {
  IconNumber1,
  IconNumber2,
  IconNumber3,
  IconNumber4,
} from "@tabler/icons-react";
import { TimelineContainer } from "../TimelineContainer";
import "../ModelsPages.scss";

const timelineItems = [
  {
    title: "Input type and constraints",
    bullet: <IconNumber1 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        The input of the model is of type text and it represents a question
        adressed to the Wikipedia knowledge base.
      </Text>
    ),
  },
  {
    title: "Select output format",
    bullet: <IconNumber2 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        You can choose in which format to view the response of the model, text
        or JSON.
      </Text>
    ),
  },
  {
    title: "Run the model",
    bullet: <IconNumber3 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        To start the model processing simply press the green terminal button
        ("Run model").
      </Text>
    ),
  },
  {
    title: "Analyzing model results",
    bullet: <IconNumber4 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        The result of the model consists of <b>five</b> answers with a{" "}
        <b>score</b> attached to each one. Results are displayed in the order of
        relevance (the first is the most relevant answer to your question).
        <Space />
        The <b>score</b> value denotes how good is the answer for your question.
        <Space />
        Each response is taken from a different Wikipedia page.
      </Text>
    ),
  },
];
export const ModelDescriptionCard = () => {
  return (
    <ModelDescriptionContainer
      introduction={
        <Text>
          The <span className="wrapper--span-blue-filled">Open Domain QA</span>{" "}
          model is trained to answer questions based on a given knowledge base
          (a documents collection convering a wide range of topics, a company's
          emails and messages etc.).
          <Space h="xs" />
          The two <b>main challenges</b> of Open Domain QA are identifying the
          answer to a given question and presenting the source of the response
          (locating the document or paragraph from where the answer was
          retrieved).
          <Space h="xs" />
          This demo model uses <b>1 million English Wikipedia</b> pages as a
          it's knowledge base.
        </Text>
      }
      languages={
        <Text>
          For custom solutions we support up to{" "}
          <b className="wrapper--span-blue-filled">100 languages</b>
          .
          <Space />
          The Trial Open Domain QA model is limited to the <b>English </b>
          language.
        </Text>
      }
      quickStart={<TimelineContainer timelineItems={timelineItems} />}
    ></ModelDescriptionContainer>
  );
};
