import { LoginPage } from "../LogInPage/LoginPage";
import { SignUpPage } from "../SingUpPage/SignUpPage";
import { AuthenticationProtectedRoute } from "./ProtectedRoute";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { EmailForPasswordReset } from "../PasswordResetPage/EmailForPasswordReset";
import { SubscriptionPage } from "../LandingPage/ProfilePages/SubscriptionPage";
import { NerPage } from "../LandingPage/ModelsPages/Ner/NerPage";
import { ApiKeyPage } from "../LandingPage/ProfilePages/ApiKeyPage";
import { EntityRelationsPage } from "../LandingPage/ModelsPages/EntityRelations/EntityRelationsPage";
import { TextClassificationPage } from "../LandingPage/ModelsPages/TextClassification/TextClassificationPage";
import { TextQaPage } from "../LandingPage/ModelsPages/TextQa/TextQaPage";
import { TextSummarizationPage } from "../LandingPage/ModelsPages/TextSummarization/TextSummarizationPage";
import { OpenDomainQaPage } from "../LandingPage/ModelsPages/OpenDomainQa/OpenDomainQaPage";
import { TextGeneratorPage } from "../LandingPage/ModelsPages/TextGenerator/TextGeneratorPage";
import { GettingStartedPage } from "../LandingPage/ProfilePages/GettingStartedPage";

export const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Navigate to="/login" />} />
        <Route
          exact
          path="/subscription"
          element={
            <AuthenticationProtectedRoute>
              <SubscriptionPage />
            </AuthenticationProtectedRoute>
          }
        />
        <Route
          exact
          path="/api-keys"
          element={
            <AuthenticationProtectedRoute>
              <ApiKeyPage />
            </AuthenticationProtectedRoute>
          }
        />
        <Route
          exact
          path="/ner"
          element={
            <AuthenticationProtectedRoute>
              <NerPage />
            </AuthenticationProtectedRoute>
          }
        />

        <Route
          exact
          path="/entity-relations"
          element={
            <AuthenticationProtectedRoute>
              <EntityRelationsPage />
            </AuthenticationProtectedRoute>
          }
        />
        <Route
          exact
          path="/text-classification"
          element={
            <AuthenticationProtectedRoute>
              <TextClassificationPage />
            </AuthenticationProtectedRoute>
          }
        />
        <Route
          exact
          path="/text-qa"
          element={
            <AuthenticationProtectedRoute>
              <TextQaPage />
            </AuthenticationProtectedRoute>
          }
        />
        <Route
          exact
          path="/text-summarization"
          element={
            <AuthenticationProtectedRoute>
              <TextSummarizationPage />
            </AuthenticationProtectedRoute>
          }
        />
        <Route
          exact
          path="/open-domain-qa"
          element={
            <AuthenticationProtectedRoute>
              <OpenDomainQaPage />
            </AuthenticationProtectedRoute>
          }
        />
        <Route
          exact
          path="/text-generator"
          element={
            <AuthenticationProtectedRoute>
              <TextGeneratorPage />
            </AuthenticationProtectedRoute>
          }
        />
        <Route
          exact
          path="/getting-started"
          element={
            <AuthenticationProtectedRoute>
              <GettingStartedPage />
            </AuthenticationProtectedRoute>
          }
        />

        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/signup" element={<SignUpPage />} />
        <Route
          exact
          path="/password-reset-email"
          element={<EmailForPasswordReset />}
        />
      </Routes>
    </BrowserRouter>
  );
};
