import "./SignUpPage.scss";
import { Paper, Title, Text, Image, Space } from "@mantine/core";

export const PresentationCard = ({ image, title, description, isMobile }) => {
  return (
    <Paper className="form" p="xl" shadow="xs" withBorder radius={15}>
      <div className="wrapper--presentation-card">
        <Image height={isMobile ? "42vw" : "50rem"} fit="contain" src={image} />
        <div>
          <Title size={isMobile ? "3.5vw" : "1.3rem"}>{title}</Title>
          <Space h="xs" />
          <Text size={isMobile ? "3vw" : "1.2rem"}>{description}</Text>
        </div>
      </div>
    </Paper>
  );
};
