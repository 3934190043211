import { Stack, Textarea } from "@mantine/core";
import { useCallback } from "react";

export const NerInputText = ({ setModelInput, currentModelInput }) => {
  const onTextChange = useCallback(
    (event) => {
      setModelInput(event.target.value);
    },
    [setModelInput]
  );
  return (
    <Stack gap={0} h="17.4rem" ml="1rem">
      <Textarea
        p={0}
        ml="2rem"
        mr="1rem"
        mb="1rem"
        w="90%"
        minRows={1}
        maxRows={10}
        autosize
        placeholder="Your input goes here..."
        variant="unstyled"
        color="#F9F9F9"
        styles={{ inputContainer: { backgroundColor: "blue" } }}
        onChange={onTextChange}
        value={currentModelInput ? currentModelInput : undefined}
      ></Textarea>
    </Stack>
  );
};
