import { Pill, Tooltip } from "@mantine/core";
import "../ModelsPages.scss";

export const NerEntityPill = ({ description, tooltip, forResults }) => {
  return (
    <Tooltip label={tooltip}>
      <Pill
        fw={700}
        c={forResults ? "#616161" : "blue"}
        className="container__entity-tag-ner"
      >
        {description}
      </Pill>
    </Tooltip>
  );
};
