import { Pill, PillsInput, Space, Text } from "@mantine/core";
import { NerEntityPill } from "./NerEntityPill";
import {
  IconNumber1,
  IconNumber2,
  IconNumber3,
  IconNumber4,
} from "@tabler/icons-react";
import { TimelineContainer } from "../TimelineContainer";
import { ModelDescriptionContainer } from "../ModelDescriptionContainer";
import "../ModelsPages.scss";

export const NER_ENTITIES = {
  PERSON: "Persons",
  NORP: "Nationalities, religious, political groups",
  ORG: "Organizations, institutions, companies",
  LOC: "Non-GPE locations",
  GPE: "Countries, cities, states",
  DATE: "Time periods, dates",
  MONEY: "Monetary",
  FAC: "Buildings, airports, highways etc.",
  PRODUCT: "Objects, food products, vehicles etc.",
  EVENT: "Any kind of events, sports, wars etc.",
  WORK_OF_ART: "Book titles, songs etc.",
  LAW: "Law numbers, legislations articles etc.",
  LANGUAGE: "Languages",
  TIME: "Any time value les than a day",
  PERCENT: "Percentage with %",
  QUANTITY: "Measurements, weight, distance etc.",
  ORDINAL: "Ordinal value like first, second etc.",
  CARDINAL: "Numeric values",
};
const timelineItems = [
  {
    title: "Select input type",
    bullet: <IconNumber1 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        To test the NER model you will need to select the type of input first
        (text or file).
      </Text>
    ),
  },
  {
    title: "Input limits",
    bullet: <IconNumber2 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        For text input there is a 5 pages of text limit. In trial mode the file
        input limit is 2 pages (for .pdf, .docx, .doc). <Space />
        Scanned pdf documents can also be provided as input for we perform OCR.
        <Space />
        In trial mode, the limit for .txt and .png/.jpeg is 1MB.
      </Text>
    ),
  },
  {
    title: "Select output format",
    bullet: <IconNumber3 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        After the text is analyzed you can choose in which format to display the
        results (text or json).
      </Text>
    ),
  },
  {
    title: "Text analysis with NER",
    bullet: <IconNumber4 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        To start analysing the text simply press the "Run model" button.
        <Space />
        After the model analyses your input the results will be displayed in the
        format you selected.
      </Text>
    ),
  },
];

export const ModelDescriptionCard = () => {
  return (
    <ModelDescriptionContainer
      introduction={
        <>
          <Text w="60rem">
            <span className="wrapper--span-blue-filled">
              Named Entity Recognition (NER)
            </span>{" "}
            classifies tokens in text into predefined categories (tags), such as
            person names, quantity expressions, percentage expressions, names of
            locations, organizations, as well as expression of time, currency
            and others.
            <Space />
            This model can recognize up to 18 entities in 104 languages.
          </Text>
          <PillsInput variant="unstyled" w="80%" mt="1rem">
            <Pill.Group>
              {Object.entries(NER_ENTITIES).map(([tagEntity, description]) => (
                <NerEntityPill description={tagEntity} tooltip={description} />
              ))}
            </Pill.Group>
          </PillsInput>
        </>
      }
      quickStart={<TimelineContainer timelineItems={timelineItems} />}
      languages={
        <Text>
          This trial model can be tested on up to{" "}
          <b className="wrapper--span-blue-filled">104 languages</b> (Romanian,
          English, Spanish, French, Arabic etc.).
          <Space />A custom NER model can be implemented for any language that
          is based on alphanumeric characters and also for Arabic.
        </Text>
      }
    ></ModelDescriptionContainer>
  );
};
