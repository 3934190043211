import { CheckIcon, Group, Radio, Stack, Paper } from "@mantine/core";
import { useCallback, useState } from "react";
import { NerInputText } from "../Ner/NerInputText";
import { NerInputFile } from "../Ner/NerInputFile";
import { useAuth } from "../../../AuthenticationProvider";
import { RunModelControls } from "../RunModelControls";
import { callModel } from "../../helpers";
import { useInfoModal } from "../../../InfoModalProvider";

const INPUT_TYPE_TEXT = "text";
const INPUT_TYPE_FILE = "file";
// const defaultInput = `
// Colorado's Supreme Court has ruled that Donald Trump cannot run for president next year in the state, citing a constitutional insurrection clause. The court ruled 4-3 that Mr Trump was not an eligible candidate because he had engaged in an insurrection over the US Capitol riot nearly three years ago. It does not stop Mr Trump running in the other states and his campaign says it will appeal to the US Supreme Court. The decision, they said, was "completely flawed". The ruling only mentions the state's primary election on 5 March, when Republican voters will choose their preferred candidate for president. But it could affect the general election in Colorado next November. It is the first ever use of Section 3 of the US Constitution's 14th Amendment to disqualify a presidential candidate. Tuesday's decision - which has been placed on hold pending appeal until next month - only applies in Colorado. Similar attempts to kick Mr Trump off the ballot in New Hampshire, Minnesota and Michigan have failed. The justices wrote in their ruling: "We do not reach these conclusions lightly. We are mindful of the magnitude and weight of the questions now before us. "We are likewise mindful of our solemn duty to apply the law, without fear or favor, and without being swayed by public reaction to the decisions that the law mandates we reach." The decision reverses an earlier one from a Colorado judge, who ruled that the 14th Amendment's insurrection ban did not apply to presidents because the section did not explicitly mention them.
// `;
const defaultInput = `
Merkel was born Angela Dorothea Kasner in 1954, in Hamburg, West Germany, the daughter of Horst Kasner (1926–2011; né Kaźmierczak), a Lutheran pastor and a native of Berlin, and his wife Herlind (1928–2019; née Jentzsch), born in Danzig (now Gdańsk, Poland), a teacher of English and Latin. She has two younger siblings, Marcus Kasner, a physicist, and Irene Kasner, an occupational therapist. In her childhood and youth, Merkel was known among her peers by the nickname "Kasi", derived from her last name Kasner.

Merkel is of German and Polish descent. Her paternal grandfather, Ludwik Kasner, was a German policeman of Polish ethnicity, who had taken part in Poland's struggle for independence in the early 20th century.[25] He married Merkel's grandmother Margarethe, a German from Berlin, and relocated to her hometown where he worked in the police. In 1930, they Germanized the Polish name Kaźmierczak to Kasner. Merkel's maternal grandparents were the Danzig politician Willi Jentzsch, and Gertrud Alma (née Drange), a daughter of the city clerk of Elbing (now Elbląg, Poland) Emil Drange. Since the mid-1990s, Merkel has publicly mentioned her Polish heritage on several occasions and described herself as a quarter Polish, but her Polish roots became better known as a result of a 2013 biography.

Religion played a key role in the Kasner family's migration from West Germany to East Germany. Merkel's paternal grandfather was originally Catholic but the entire family converted to Lutheranism during the childhood of her father,[27] who later studied Lutheran theology in Heidelberg and Hamburg. In 1954, when Angela was just three months old, her father received a pastorate at the church in Quitzow [de] (a quarter of Perleberg in Brandenburg), which was then in East Germany. The family moved to Templin and Merkel grew up in the countryside 90 km (56 mi) north of East Berlin.

In 1968, Merkel joined the Free German Youth (FDJ), the official communist youth movement sponsored by the ruling Marxist–Leninist Socialist Unity Party of Germany. Membership was nominally voluntary, but those who did not join found it difficult to gain admission to higher education. She did not participate in the secular coming of age ceremony Jugendweihe, however, which was common in East Germany. Instead, she was confirmed. During this time, she participated in several compulsory courses on Marxism-Leninism with her grades only being regarded as "sufficient". Merkel later said that "Life in the GDR was sometimes almost comfortable in a certain way, because there were some things one simply couldn't influence."

`;
const controller = new AbortController();
const signal = controller.signal;
export const UserInputCard = ({ setResults }) => {
  const { open, setModalMessage } = useInfoModal();
  const [inputType, setInputType] = useState(INPUT_TYPE_TEXT);
  const [modelRunning, setModelRunning] = useState(false);
  const [modelInput, setModelInput] = useState(defaultInput);
  const { apiKey } = useAuth();
  const onRunModelClick = useCallback(() => {
    if (apiKey && modelInput && setResults) {
      let body =
        inputType === INPUT_TYPE_TEXT
          ? { text: modelInput }
          : { file: modelInput };
      body = { ...body, extractEntityRelation: true };
      const formData = new FormData();
      formData.append("apiKey", apiKey);
      if (body.text) {
        formData.append("text", body.text);
      }
      if (body.file) {
        formData.append("file", body.file);
      }
      formData.append("extractEntityRelation", body.extractEntityRelation);
      setModelRunning(true);
      callModel({
        setResults,
        setModelRunning,
        signal,
        controller,
        formData,
        modelNr: 1,
        openInfoModal: open,
        setModalMessage,
      });
    }
  }, [
    apiKey,
    inputType,
    modelInput,
    setModelRunning,
    setResults,
    open,
    setModalMessage,
  ]);

  return (
    <Paper withBorder radius="md">
      <Stack justify="flex-start" h="100%" gap={0}>
        <Group justify="space-between" p="2rem" gap="6rem">
          <Radio.Group size="1.2rem" value={inputType} onChange={setInputType}>
            <Group gap="3.5rem">
              <Radio
                style={{ caretColor: "transparent" }}
                value="text"
                label="Text"
                c="#403F3F"
                icon={CheckIcon}
              ></Radio>
              <Radio
                style={{ caretColor: "transparent" }}
                value="file"
                label="File"
                c="#403F3F"
                icon={CheckIcon}
              ></Radio>
            </Group>
          </Radio.Group>
          <RunModelControls
            modelRunning={modelRunning}
            onRunModelClick={onRunModelClick}
            modelInput={modelInput}
          />
        </Group>

        {inputType === INPUT_TYPE_TEXT && (
          <NerInputText
            setModelInput={setModelInput}
            currentModelInput={modelInput}
          />
        )}
        {inputType === INPUT_TYPE_FILE && (
          <NerInputFile
            setModelInput={setModelInput}
            currentModelInput={modelInput}
          />
        )}
      </Stack>
    </Paper>
  );
};
