import { Group, Paper, Stack, TextInput } from "@mantine/core";
import { useCallback, useState } from "react";
import { useAuth } from "../../../AuthenticationProvider";
import { IconQuestionMark } from "@tabler/icons-react";
import { NerInputText } from "../Ner/NerInputText";
import { RunModelControls } from "../RunModelControls";
import { callModel } from "../../helpers";
import { useInfoModal } from "../../../InfoModalProvider";

const defaultTextInput = `
The wedding of Prince Harry and Meghan Markle was held on Saturday 19 May 2018 in St George's Chapel at Windsor Castle in the United Kingdom. The groom is a member of the British royal family; the bride is American and previously worked as an actress, blogger, charity ambassador, and advocate. On the morning of the wedding, Prince Harry's grandmother, Queen Elizabeth II, conferred upon him the titles of Duke of Sussex, Earl of Dumbarton and Baron Kilkeel. On her marriage, Markle gained the style Her Royal Highness and titles Duchess of Sussex, Countess of Dumbarton and Baroness Kilkeel. Justin Welby, Archbishop of Canterbury, officiated at the wedding using the standard Anglican church service for Holy Matrimony published in Common Worship, a liturgical text of the Church of England. The traditional ceremony was noted for the inclusion of African-American culture.
`;
const defaultQuestion = `What priest officiated the Meghan Markle's and Harry wedding?`;
const controller = new AbortController();
const signal = controller.signal;
export const UserInputCard = ({ setResults }) => {
  const { open, setModalMessage } = useInfoModal();
  const [modelRunning, setModelRunning] = useState(false);
  const [modelInput, setModelInput] = useState(defaultTextInput);
  const [question, setQuestion] = useState(defaultQuestion);
  const { apiKey } = useAuth();
  const onQuestionChange = (event) => {
    setQuestion(event.target.value);
  };
  const onRunModelClick = useCallback(() => {
    if (apiKey && modelInput && setResults && open && setModalMessage) {
      const body = {
        text: modelInput,
        question: question,
      };
      setModelRunning(true);
      const formData = new FormData();
      formData.append("apiKey", apiKey);
      formData.append("text", body.text);
      formData.append("question", body.question);
      callModel({
        setResults,
        setModelRunning,
        controller,
        signal,
        formData,
        modelNr: 3,
        setModalMessage,
        openInfoModal: open,
      });
    }
  }, [
    apiKey,
    modelInput,
    setModelRunning,
    setResults,
    question,
    open,
    setModalMessage,
  ]);
  return (
    <Paper withBorder radius="md">
      <Stack justify="flex-start" h="100%" gap={0}>
        <Group justify="space-between" p="2rem" gap="6rem" pb="0.5rem">
          <TextInput
            leftSectionPointerEvents="none"
            leftSection={
              <IconQuestionMark
                size={17}
                color="var(--mantine-color-blue-filled)"
              />
            }
            description="Question"
            placeholder="Your question regarding text"
            w="35vw"
            styles={{
              description: { fontWeight: "700" },
              input: { borderColor: "transparent" },
            }}
            onChange={(event) => onQuestionChange(event)}
            defaultValue={defaultQuestion}
          />
          <RunModelControls
            modelRunning={modelRunning}
            onRunModelClick={onRunModelClick}
            modelInput={modelInput}
          />
        </Group>
        <NerInputText
          setModelInput={setModelInput}
          currentModelInput={modelInput}
        />
      </Stack>
    </Paper>
  );
};
