import { Container, Group, Stack } from "@mantine/core";
import { PageTitle } from "../PageTitle/PageTitle";

export const ModelPageRootWrapper = ({
  pageTitle,
  modelResultsCardChild,
  inputAndModelDescriptionChild,
}) => {
  return (
    <Container size="responsive" w="100%" m={0} p={5} mt={10} bg="#F9F9F9">
      <PageTitle title={pageTitle} />
      <Group justify="space-between" grow align="flex-start" gap={0}>
        <Stack justify="flex-start" p={10} gap="0.5rem" w={"60rem"}>
          {inputAndModelDescriptionChild}
        </Stack>
        {modelResultsCardChild}
      </Group>
    </Container>
  );
};
