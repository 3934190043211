import {
  Center,
  FileButton,
  Text,
  Stack,
  Button,
  Title,
  Group,
} from "@mantine/core";
import { IconExchange, IconPlus } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import "../ModelsPages.scss";

const ACCEPTED_FILE_TYPES =
  "image/png,image/jpeg,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain";
export const NerInputFile = ({ setModelInput, currentModelInput }) => {
  const [file, setFile] = useState(null);
  useEffect(() => {
    if (file && setModelInput) {
      setModelInput(file);
    }
  }, [file, setModelInput]);
  return (
    <Stack align="center" w="100%" justify="center">
      <Center
        w="90%"
        h="14.4rem"
        bg="#FCFCFC"
        mb="3rem"
        className="container--file-upload"
      >
        <Stack align="center" gap={file ? "0rem" : "1.5rem"}>
          <Group>
            <FileButton onChange={setFile} accept={ACCEPTED_FILE_TYPES}>
              {(props) =>
                !file ? (
                  <Button leftSection={<IconPlus size={14} />} {...props}>
                    <Text fw={700}>Upload file</Text>
                  </Button>
                ) : (
                  <Group>
                    <Button
                      leftSection={<IconExchange size={14} />}
                      {...props}
                      w="10rem"
                    >
                      <Text fw={700}>Change file</Text>
                    </Button>
                  </Group>
                )
              }
            </FileButton>
          </Group>

          {(file && (
            <Text m={0} size="sm" ta="center" mt="sm" c="dimmed">
              {file.name}
            </Text>
          )) || (
            <Stack gap={0} align="center">
              <Title m={0} order={6} c="#403F3F">
                Supported files
              </Title>
              <Text c="dimmed" mt={0} size="sm" ta="center">
                pdf, doc, docx, jpeg, png, txt
              </Text>
            </Stack>
          )}
        </Stack>
      </Center>
    </Stack>
  );
};
