import { Group, Stack, Text } from "@mantine/core";
import { useEffect, useState } from "react";

export const TextFormatResults = ({
  result,
  setResultDisplayed,
  resultDisplayed,
}) => {
  const [displayResponse, setDisplayResponse] = useState(
    resultDisplayed ? result : ""
  );

  useEffect(() => {
    if (!resultDisplayed && result) {
      let characterPosition = 0;
      const intervalId = setInterval(() => {
        setDisplayResponse(result?.slice(0, characterPosition));
        characterPosition++;
        if (characterPosition > result.length) {
          clearInterval(intervalId);
          setResultDisplayed(true);
        }
      }, 20);
    }
  }, [result, resultDisplayed, setResultDisplayed]);
  return (
    <Stack mt="2rem" p={25}>
      <Group justify="flex-start">
        <Text c="dimmed">{displayResponse}</Text>
      </Group>
    </Stack>
  );
};
