import "./App.scss";
import { AuthenticationProvider } from "./components/AuthenticationProvider";
import { Routing } from "./components/Routing/Routing";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { themeOverrides } from "./mantineTheme.overrides";
import { InfoModalProvider } from "./components/InfoModalProvider";
import { CenteredModal } from "./components/LandingPage/ModelsPages/CenteredModal";

function App() {
  const cssVariablesResolver = () => ({
    variables: {
      "--var-container-create-account-input-width-pc": "34rem",
      "--var-container-create-account-input-width-phone": "40rem",
      "--var-container-create-account-input-width-phone-xs": "2rem",
    },
    light: {
      "--mantine-color-gray-1": "#585858",
      "--mantine-color-gray-2": "#F4F4F4",
      "--mantine-color-gray-3": "#F5F5F5",
      "--mantine-color-gray-4": "#ececf1",
      "--mantine-color-white-1": "#FAFAFA",
    },
  });

  return (
    <MantineProvider
      theme={themeOverrides}
      cssVariablesResolver={cssVariablesResolver}
    >
      <Notifications position="top-right" limit={1} />
      <AuthenticationProvider>
        <InfoModalProvider>
          <CenteredModal />
          <div>
            <Routing />
          </div>
        </InfoModalProvider>
      </AuthenticationProvider>
    </MantineProvider>
  );
}

export default App;
