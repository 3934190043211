import "./SignUpPage.scss";
import "@mantine/core/styles.css";
import { Header } from "../Header/Header";
import { useMediaQuery } from "@mantine/hooks";
import { PresentationContainer } from "./PresentationContainer";
import { CreateAccountContainer } from "./CreateAccounContainer";
import { Group } from "@mantine/core";

export const SignUpPage = () => {
  const isMobile = useMediaQuery("all and (max-width: 750px)");
  const isTablet = useMediaQuery("all and (max-width: 1501px)");
  return (
    <Group justify="center">
      <div className={isMobile ? "wrapper--signup" : "wrapper--signup is-zoom"}>
        <Header />
        <div className="wrapper">
          <PresentationContainer isMobile={isMobile} />
          <CreateAccountContainer isMobile={isMobile} />
        </div>
      </div>
    </Group>
  );
};
