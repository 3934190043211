import React, { useCallback } from "react";
import { UnstyledButton, Group, Avatar, Text, rem, Stack } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import "./UserButton.scss";
import { useContext } from "react";
import { AuthenticationContext } from "../../AuthenticationProvider";

export const UserButton = () => {
  const { currentUserName, currentUserEmail } = useContext(
    AuthenticationContext
  );
  const getAvatarInitials = useCallback(() => {
    if (currentUserName) {
      const words = currentUserName.split(" ");
      return `${words[0][0].toUpperCase()}${words[1][0].toUpperCase()}`;
    }
    return "";
  }, [currentUserName]);
  return (
    <UnstyledButton className="user">
      <Group justify="space-between">
        <Group>
          <Avatar radius="xl">{getAvatarInitials()}</Avatar>
          <Stack gap={0}>
            <Text size="sm" fw={500}>
              {currentUserName}
            </Text>

            <Text c="dimmed" size="xs">
              {currentUserEmail}
            </Text>
          </Stack>
        </Group>
        <IconChevronRight className="container--icon-chevron" stroke={1.5} />
      </Group>
    </UnstyledButton>
  );
};
