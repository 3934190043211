const jsonNerResponseBody = {
  entityPredictions: "entity_prediction",
  inputTokens: "input",
  entityValues: "ner_out_tags_only",
  responseTokensWithAssociatedTag: "ner_out_token",
  prediction: "prediction",
  probability: "probability",
};

export const getEntitiesWithValues = (nerResponseBody) => {
  const entityPredictions =
    nerResponseBody[jsonNerResponseBody.entityPredictions];
  let result = {};
  entityPredictions.forEach((entityTag) =>
    entityTag.forEach((entityTagValue) => {
      if (result[entityTagValue.type]) {
        result[entityTagValue.type] = [
          ...result[entityTagValue.type],
          { ...entityTagValue },
        ];
      } else {
        result[entityTagValue.type] = [{ ...entityTagValue }];
      }
    })
  );
  return result;
};
