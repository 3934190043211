import { NerTextResults } from "./NerTextResults";
import { GeneralModelResultsCard } from "../GeneralModelResultsCard";
export const ModelResultsCard = ({ results }) => {
  return (
    <GeneralModelResultsCard
      results={results}
      textFormatResultsComponent={<NerTextResults results={results} />}
    />
  );
};
