import { Text, Space } from "@mantine/core";
import {
  IconNumber1,
  IconNumber2,
  IconNumber3,
  IconNumber4,
  IconNumber5,
} from "@tabler/icons-react";
import { TimelineContainer } from "../TimelineContainer";
import { ModelDescriptionContainer } from "../ModelDescriptionContainer";
import "../ModelsPages.scss"

const timelineItems = [
  {
    title: "Input type and constraints",
    bullet: <IconNumber1 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        For trial account, the input type for text classification is text with a
        maximum length approximately equal to 5 pages of written text.
      </Text>
    ),
  },
  {
    title: "Select output format",
    bullet: <IconNumber2 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        After the text is classified you can choose in which format to display
        the results (text or json).
      </Text>
    ),
  },
  {
    title: "Add your classification categories",
    bullet: <IconNumber3 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        You can add up to 5 categories in which you wish the text to be
        classified.
      </Text>
    ),
  },
  {
    title: "Text classification",
    bullet: <IconNumber4 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        To test the Text Classification model you only have to press the green
        terminal button ("Run model") and wait for the response to come back.
        <Space />
        You can monitor the time by looking at the clock that will start right
        after you prest the terminal button.
      </Text>
    ),
  },
  {
    title: "Output values",
    bullet: <IconNumber5 size={17} />,
    child: (
      <Text c="dimmed" size="sm">
        The output values of the model consist of the category names and an
        associated confidence score.
        <Space />
        The <b>confidence score</b> represents the probability of the text to
        belong to a certain category given as an input.
      </Text>
    ),
  },
];
export const ModelDescriptionCard = () => {
  return (
    <ModelDescriptionContainer
      introduction={
        <Text w="100%">
          The{" "}
          <span className="wrapper--span-blue-filled">
            Text classification
          </span>{" "}
          model is used to classify text into multiple categories.{" "}
          <Space></Space>These categories are not predefined so you can change
          them anytime. Usually this model is a good choice if you want to
          automatically classify large numbers of documents into different
          categories.
        </Text>
      }
      languages={
        <Text>
          We support up to{" "}
          <b className="wrapper--span-blue-filled">
            100 languages
          </b>{" "}
          for a custom solution.
          <Space />
          The Trial account supports{" "}
          <b>
            English, Romanian, Spanish and French
          </b>
          .
        </Text>
      }
      example={
        <>
          <Text w="100%">
            Given the following text that represents an email talking about a
            new company's product release: <Space h="md" />
            <Text ml="xs">
              "Dear customer,
              <Space h="sm" /> We hope this message finds you well and thriving.
              At our company, we are committed to creating products that not
              only meet but exceed your expectations. Today, we are excited to
              introduce our latest innovation.
              <Space h="sm" /> Our new product is more than just a product; it's
              an experience tailored to elevate your everyday moments. Whether
              you're a seasoned professional or someone exploring new
              possibilities, we believe that we will redefine the way you enjoy
              using our products."
            </Text>
          </Text>
          <Space h="md" />
          <Text>
            We wish to classify it in one of the following categories: product
            advertising, sports, new product announcement, money.
          </Text>
          <Space h="md" />
          <Text>The expected model result would be something like:</Text>
          <Space h="xs" />
          <Text ml="xs">
            money: <b>26%</b> <Space />
            new product announcement: <b>99%</b> <Space />
            product advertising: <b>88%</b> <Space />
            sports: <b>24%</b>
          </Text>
        </>
      }
      quickStart={<TimelineContainer timelineItems={timelineItems} />}
    ></ModelDescriptionContainer>
  );
};
