import { Accordion, Group, ScrollArea, Text, Tooltip } from "@mantine/core";
import { ContainerForTagValues } from "./ContainerForTagValues";
import { getEntitiesWithValues } from "./helpers";
import { NER_ENTITIES } from "./ModelDescriptionCard";

export const NerTextResults = ({ results }) => {
  return (
    <ScrollArea h="80vh">
      {results &&
        Object.entries(getEntitiesWithValues(results)).map(
          (resultsForOneEntityTag) => {
            const entityTagName = resultsForOneEntityTag[0];
            const entityTagValues = resultsForOneEntityTag[1];
            return (
              <Group justify="center" mt="2rem">
                <Accordion w="90%">
                  <Accordion.Item value={entityTagName}>
                    <Accordion.Control>
                      <Group justify="flex-start">
                        <Tooltip
                          label={NER_ENTITIES[entityTagName?.split("-")[1]]}
                        >
                          <Text fw={700} c="blue">
                            {entityTagName?.split("-")[1]}
                          </Text>
                        </Tooltip>
                      </Group>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <ContainerForTagValues
                        entityTagValues={entityTagValues}
                      />
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              </Group>
            );
          }
        )}
    </ScrollArea>
  );
};
