import "./Header.scss";
import { Image, Text } from "@mantine/core";

export const Header = () => {
  return (
    <div className="wrapper">
      <div className="wrapper--inner">
        <Image
          w="15rem"
          fit="contain"
          src={require("../../assets/ontegra-logo.png")}
          className="logo--selection"
        />
        <Text
         className="text__ai--login"
          mb={30}
        >
          AI
        </Text>
      </div>
    </div>
  );
};
