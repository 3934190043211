import { Group, ActionIcon, Anchor } from "@mantine/core";
import {
  IconBrandTwitter,
  IconBrandLinkedin,
  IconBrandFacebook,
  IconBrandXing,
  IconHome,
} from "@tabler/icons-react";
import "./SocialMediaBanner.scss";

export const SocialMediaBanner = ({ iconsSize, mt, isMobile, isTablet }) => {
  const iconsArray = [
    { c: <IconHome stroke={1.5} />, href: "https://ontegra.com/" },
    {
      c: <IconBrandLinkedin stroke={1.5} />,
      href: "https://www.linkedin.com/company/ontegra/mycompany/",
    },
    {
      c: <IconBrandFacebook stroke={1.5} />,
      href: "https://www.facebook.com/ontegrasolutions",
    },
    {
      c: <IconBrandTwitter stroke={1.5} />,
      href: "https://twitter.com/i/flow/login?redirect_after_login=%2FOntegraOffice",
    },
    {
      c: <IconBrandXing stroke={1.5} />,
      href: "https://www.xing.com/pages/ontegrasoftwaresolutions",
    },
  ];

  return (
    <div className="container--footer">
      <div>
        <Group
          mt={mt}
          gap="xs"
          justify="center"
          wrap="nowrap"
          h={isMobile ? "4rem" : isTablet ? "3rem" : "2.5rem"}
        >
          {iconsArray.map((icon) => (
            <ActionIcon
              size={isMobile ? "4rem" : isTablet ? "3.5rem" : "3rem"}
              variant="subtle"
              radius="xl"
            >
              <Anchor href={icon.href}>{icon.c}</Anchor>
            </ActionIcon>
          ))}
        </Group>
      </div>
    </div>
  );
};
