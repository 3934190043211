import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { firebaseAuth } from "../../firebase/firebaseInit";
import {
  TextInput,
  PasswordInput,
  Button,
  Title,
  Text,
  Container,
} from "@mantine/core";
import "./LoginPage.scss";
import { Header } from "../Header/Header";
import { useMediaQuery } from "@mantine/hooks";
import { JWT_TOKEN_KEY } from "../../utils/constants";
import { notifications } from "@mantine/notifications";
import { preValidateEmailAndPassword } from "./helpers";
import { SocialMediaBanner } from "../SocialMediaBanner/SocialMediaBanner";

export const LoginPage = () => {
  const isMobile = useMediaQuery("all and (max-width: 750px)");
  const isTablet = useMediaQuery("all and (max-width: 1501px)");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const navigate = useNavigate();
  const onEmailChange = (event, isInvalid = false) => {
    if (isInvalid) resetInvalidInputs();
    setEmail(event.target.value);
  };
  const onPasswordChange = (event, isInvalid = false) => {
    if (isInvalid) resetInvalidInputs();
    setPassword(event.target.value);
  };
  const resetInvalidInputs = () => {
    setInvalidCredentials(false);
  };
  const onLogin = useCallback(() => {
    const areCredentialsPrevalid = preValidateEmailAndPassword(email, password);
    if (areCredentialsPrevalid) {
      signInWithEmailAndPassword(firebaseAuth, email, password)
        .then((userCredentials) => {
          if (!userCredentials.user.emailVerified) {
            notifications.show({
              loading: false,
              title: "Your account has not been verified!",
              message: "Check your email inbox to verify",
              autoClose: 3000,
              color: "red",
            });
          } else {
            userCredentials.user.getIdToken().then((token) => {
              sessionStorage.setItem(JWT_TOKEN_KEY, token);
              notifications.show({
                loading: false,
                title: "Sucessfull login!",
                message: "Welcome back to Ontegra AI!",
                autoClose: 3000,
                color: "green",
              });
              navigate("/api-keys");
            });
          }
        })
        .catch((error) => {
          notifications.show({
            loading: false,
            title: "Invalid credentials!",
            message: "Check and try again",
            autoClose: 3000,
            color: "red",
          });
        });
    } else {
      setInvalidCredentials(true);
      notifications.show({
        loading: false,
        title: "Invalid credentials provided!",
        autoClose: 3000,
        color: "red",
      });
    }
  }, [email, navigate, password]);

  return (
    <Container size={420}>
      <Header />
      <div className="wrapper">
        <div className="container__text-inputs--centered">
          <Title mb={18} size="2rem" className="title" ta="center">
            Welcome back
          </Title>
          {invalidCredentials ? (
            <TextInput
              label="Company email"
              placeholder="Invalid company email"
              mt="lg"
              size="xl"
              className="input--text"
              withAsterisk
              error
              onChange={(event) => onEmailChange(event, true)}
            />
          ) : (
            <TextInput
              label="Company email"
              placeholder=""
              mt="lg"
              size="xl"
              className="input--text"
              withAsterisk
              onChange={onEmailChange}
            />
          )}
          {invalidCredentials ? (
            <PasswordInput
              label="Password"
              placeholder="Invalid password"
              mt="lg"
              size="xl"
              className="input--text"
              withAsterisk
              error
              onChange={(event) => onPasswordChange(event, true)}
            />
          ) : (
            <PasswordInput
              label="Password"
              mt="lg"
              size="xl"
              className="input--text"
              withAsterisk
              onChange={onPasswordChange}
            />
          )}

          <Button fullWidth mt="xl" size="lg" onClick={onLogin}>
            Log in
          </Button>
          <Text size="1rem" mt="1.5rem" ta="center" c="dimmed">
            Don't have an account? <a href="/signup">Sign up</a>
          </Text>
          <Text mt="1rem" ta="center">
            <a href="/password-reset-email">Forgot password?</a>
          </Text>
          {(isMobile || isTablet) && (
            <SocialMediaBanner
              isMobile={isMobile}
              isTablet={isTablet}
              iconsSize={"3rem"}
            />
          )}
        </div>
      </div>
    </Container>
  );
};
