const jsonTextQaResponseBody = {
  modelResponse: "TEXT_GENERATED",
  answerToQuestion: "answer",
};

export const parseTextQaResponseForTextDisplay = (response) => {
  return {
    answer:
      response[jsonTextQaResponseBody.modelResponse][0][
        jsonTextQaResponseBody.answerToQuestion
      ],
  };
};
