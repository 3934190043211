import { Paper, Stack } from "@mantine/core";

export const UserInputWrapper = ({ children }) => {
  return (
    <Paper withBorder radius="md">
      <Stack justify="flex-start" h="100%" gap={0}>
        {children}
      </Stack>
    </Paper>
  );
};
