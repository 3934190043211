import { ActionIcon, Group, Text, Tooltip } from "@mantine/core";
import { IconPlayerPause, IconTerminal } from "@tabler/icons-react";
import { useEffect } from "react";
import { useStopwatch } from "react-timer-hook";
import "./ModelsPages.scss";

export const RunModelControls = ({
  modelRunning,
  onRunModelClick,
  modelInput,
}) => {
  const { seconds, minutes, start, reset } = useStopwatch({
    autoStart: true,
  });
  //TODO: use it if we want to stop the call to the model
  // const onModelStop = useCallback(() => {
  //   if (controller && reset && setModelRunning) {
  //     controller.abort();
  //     reset();
  //     setModelRunning(false);
  //   }
  // }, [controller, reset, setModelRunning]);
  useEffect(() => {
    if (start && reset) {
      modelRunning ? start() : reset();
    }
  }, [modelRunning, start, reset]);

  return (
    <Group mr="0">
      {!modelRunning ? (
        <Tooltip label="Run model">
          <ActionIcon
            size={35}
            styles={{ root: { backgroundColor: "green" } }}
            onClick={() => onRunModelClick()}
            disabled={modelInput ? false : true}
          >
            <IconTerminal className="wrapper--terminal-icon-size" />
          </ActionIcon>
        </Tooltip>
      ) : (
        <>
          <Text>
            <span className="wrapper--time-display">{minutes}</span>:
            <span className="wrapper--time-display">{seconds}</span>
          </Text>
          <Tooltip label="Model is running">
            <ActionIcon size={35} className="wrapper--icon-player-pause">
              <IconPlayerPause className="wrapper--terminal-icon-size" />
            </ActionIcon>
          </Tooltip>
        </>
      )}
    </Group>
  );
};
