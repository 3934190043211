import "./SignUpPage.scss";
import { useCallback, useContext, useState } from "react";
import {
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  INVALID_PASSWORD,
  PASSWORDS_DO_NOT_MATCH,
  VALID_PASSWORDS,
  signupOnServerSide,
  validateCompanyEmail,
  validateFirstName,
  validateLastName,
  validatePasswords,
  validatePhoneNumber,
} from "./helpers";
import { useNavigate } from "react-router-dom";
import { IconCheck, IconX } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { firebaseAuth } from "../../firebase/firebaseInit";
import { PasswordsContainer } from "./PasswordsContainer";
import { EmailInputContainer } from "./EmailInputContainer";
import { TextInput, Button, Title, Text, Center, rem } from "@mantine/core";
import { AuthenticationContext } from "../AuthenticationProvider";

export const CreateAccountContainer = ({ isMobile }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNr, setPhoneNr] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isCompanyEmailValid, setIsCompanyEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isPhoneNrValid, setIsPhoneNrValid] = useState(true);
  const { setApiKey } = useContext(AuthenticationContext);
  const onSubmit = useCallback(() => {
    if (!setApiKey) return;
    const body = {
      email,
      fullName: `${firstName} ${lastName}`,
      phoneNumber: phoneNr,
      password,
      confirmPassword,
    };
    const passwordsValidator = validatePasswords(body);
    const emailValidator = validateCompanyEmail(email);
    const firstNameValidator = validateFirstName(firstName);
    const lastNameValidator = validateLastName(lastName);
    let isValidForSubmit =
      passwordsValidator === VALID_PASSWORDS &&
      emailValidator &&
      firstNameValidator &&
      lastNameValidator;
    if (phoneNr) {
      const phoneNrValidator = validatePhoneNumber(phoneNr);
      isValidForSubmit = isValidForSubmit && phoneNrValidator;
      setIsPhoneNrValid(phoneNrValidator);
    }

    if (!isValidForSubmit) {
      if (passwordsValidator === INVALID_PASSWORD) {
        setIsPasswordValid(false);
      } else {
        if (passwordsValidator === PASSWORDS_DO_NOT_MATCH) {
          setPasswordsMatch(false);
        }
      }
      if (!emailValidator) setIsCompanyEmailValid(false);
      if (!firstNameValidator) setIsFirstNameValid(false);
      if (!lastNameValidator) setIsLastNameValid(false);
      notifications.show({
        loading: false,
        title: "Invalid data provided",
        message: "Please verify your personal information again!",
        color: "red",
        autoClose: 3000,
      });
    } else {
      const id = notifications.show({
        loading: true,
        title: "Email verification loading",
        message: "We are sending you an email verification",
        autoClose: false,
        withCloseButton: true,
      });
      signupOnServerSide(body)
        .then((resp) => resp?.data)
        .then(({ userRecord, newApiKey }) => {
          setApiKey(newApiKey);
          signInWithEmailAndPassword(firebaseAuth, email, password).then(
            (userCredentials) => {
              sendEmailVerification(userCredentials.user).then(() => {
          notifications.update({
            id,
            color: "teal",
            title: "Verification email sent!",
            message: "Confirm and then you can login",
            icon: (
              <IconCheck style={{ width: rem(18), height: rem(18) }} />
            ),
            loading: false,
            autoClose: 4000,
          });
                signOut(firebaseAuth);
                navigate("/login");
              });
            }
          );
        })
        .catch((error) => {
          notifications.update({
            id,
            color: "red",
            title: error.response.data.message,
            message: "",
            icon: <IconX style={{ width: rem(18), height: rem(18) }} />,
            loading: false,
            autoClose: 2000,
          });
        });
    }
  }, [
    confirmPassword,
    email,
    firstName,
    lastName,
    password,
    phoneNr,
    setApiKey,
    navigate,
  ]);

  const onEmailChange = (event, isInvalid = false) => {
    if (isInvalid) {
      resetEmailInput();
    }
    setEmail(event.target.value);
  };
  const onPasswordChange = (event, isInvalid = false) => {
    if (isInvalid) resetPasswordInput();
    setPassword(event.target.value);
  };
  const onConfirmPasswordChange = (event, isInvalid = false) => {
    if (isInvalid) resetConfirmPasswordInput();
    setConfirmPassword(event.target.value);
  };
  const onFirstNameChange = (event, isInvalid = false) => {
    if (isInvalid) resetFirstNameInput();
    setFirstName(event.target.value);
  };
  const onLastNameChange = (event, isInvalid = false) => {
    if (isInvalid) resetLastNameInput();
    setLastName(event.target.value);
  };
  const onPhoneNrChange = (event, isInvalid = false) => {
    if (isInvalid) resetPhoneNrInput();
    setPhoneNr(event.target.value);
  };

  const resetEmailInput = () => {
    setIsCompanyEmailValid(true);
  };
  const resetFirstNameInput = () => {
    setIsFirstNameValid(true);
  };
  const resetLastNameInput = () => {
    setIsLastNameValid(true);
  };
  const resetPasswordInput = () => {
    setIsPasswordValid(true);
  };
  const resetPhoneNrInput = () => {
    setIsPhoneNrValid(true);
  };
  const resetConfirmPasswordInput = () => {
    setPasswordsMatch(true);
  };
  return (
    <div className="container__middle">
      <div className="container__create-account">
        <div style={{marginBottom: "2rem"}}>
          <Title size={isMobile ? "5vw" : "2rem"} className="title" ta="center">
            Create your account!
          </Title>
          <Text c="dimmed" ta="center">
            Already have an account? <a href="/login">Login</a>
          </Text>
        </div>
        <Center style={{ gap: "5px" }} mb="lg">
          {isFirstNameValid ? (
            <TextInput
              label="First name"
              placeholder="First name"
              mt="lg"
              size="xl"
              className="text-input--width"
              onChange={onFirstNameChange}
              withAsterisk
            />
          ) : (
            <TextInput
              label="First name"
              placeholder="Invalid first name"
              mt="lg"
              size="xl"
              error
              withAsterisk
              className="text-input--width"
              onChange={(event) => onFirstNameChange(event, true)}
            />
          )}
          {isLastNameValid ? (
            <TextInput
              label="Last name"
              placeholder="Last name"
              mt="lg"
              size="xl"
              className="text-input--width"
              withAsterisk
              onChange={onLastNameChange}
            />
          ) : (
            <TextInput
              label="Last name"
              placeholder="Invalid last name"
              mt="lg"
              size="xl"
              className="text-input--width"
              withAsterisk
              error
              onChange={(event) => onLastNameChange(event, true)}
            />
          )}
        </Center>

        {isPhoneNrValid ? (
          <TextInput
            label="Phone number"
            placeholder="Phone number"
            mt="lg"
            size="xl"
            onChange={onPhoneNrChange}
          />
        ) : (
          <TextInput
            label="Phone number"
            placeholder="Phone number"
            mt="lg"
            size="xl"
            error
            onChange={(event) => onPhoneNrChange(event, true)}
          />
        )}

        <EmailInputContainer
          isCompanyEmailValid={isCompanyEmailValid}
          onEmailChange={onEmailChange}
        />

        <PasswordsContainer
          isPasswordValid={isPasswordValid}
          passwordsMatch={passwordsMatch}
          setConfirmPassword={onConfirmPasswordChange}
          setPassword={onPasswordChange}
        />
        <Button fullWidth mt="xl" size="lg" onClick={onSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
};
