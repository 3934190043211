import { TextFormatResults } from "./TextFormatResults";
import "../ModelsPages.scss";
import { GeneralModelResultsCard } from "../GeneralModelResultsCard";

export const ModelResultsCard = ({ results }) => {
  return (
    <GeneralModelResultsCard
      results={results}
      textFormatResultsComponent={<TextFormatResults results={results} />}
    />
  );
};
