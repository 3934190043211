import { Center, Container, Flex } from "@mantine/core";
import { PageTitle } from "../PageTitle/PageTitle";
import { HomeMenu } from "../Menu/HomeMenu";
import { useAuth } from "../../AuthenticationProvider";
import { useEffect } from "react";
import { getUserAccount, homePageOptions } from "../helpers";

export const GettingStartedPage = () => {
  const { currentUserEmail, setApiKey, setCurrentUserName, apiKey } = useAuth();
  useEffect(() => {
    if (!apiKey) {
      if (currentUserEmail && setApiKey && setCurrentUserName) {
        getUserAccount(currentUserEmail)
          .then((resp) => resp.data)
          .then(({ userApiKey, fullName }) => {
            setApiKey(userApiKey);
            setCurrentUserName(fullName);
          });
      }
    }
  }, [currentUserEmail, setApiKey, setCurrentUserName, apiKey]);
  return (
    <Flex>
      <HomeMenu selected={homePageOptions.DOCUMENTATION_GETTING_STARTED} />
      <Container size="responsive" w="100%" m={0} p={5}>
        <PageTitle title={"Getting started"} />
        <Center>Coming soon</Center>
      </Container>
    </Flex>
  );
};
