import "./SignUpPage.scss";
import { Space, Text } from "@mantine/core";
import { PresentationCard } from "./PresentationCard";
import nlpMastersImage from "../../assets/nlp1.png";
import partnersImage from "../../assets/partners.png";
import customizationImage from "../../assets/customization.png";
import cloudAndPremiseImage from "../../assets/cloud&onpremise.png";

export const PresentationContainer = ({ isMobile }) => {
  return (
    <div>
      <div className="container--baseline">
        <Text size={isMobile ? "5vw" : "2.1rem"} fw={600} mb="1rem">
          Start your AI journey with us!
        </Text>
        <Text size={isMobile ? "3.8vw" : "1rem"}>
          Bring the power of NLP and Computer Vision
          <Space />
          into your bussiness world.
        </Text>
      </div>
      <div className="container--presentation-cards">
        <PresentationCard
          image={nlpMastersImage}
          title={"We are Natural Language Processing masters"}
          description={
            "Our NLP models are in-house developed and amongst the best out there."
          }
          isMobile={isMobile}
        />
        <PresentationCard
          image={customizationImage}
          title={"Custom models for everyone!"}
          description={
            "We believe that every company has it's own needs thus AI solutions need to be perfectly tailored for you."
          }
          isMobile={isMobile}
        />
        <PresentationCard
          image={cloudAndPremiseImage}
          title={"Cloud and On-premise solutions"}
          description={
            "Wheter you want to use our models in cloud or on-premis nothing can stop you, we can make that happen."
          }
          isMobile={isMobile}
        />
        <PresentationCard
          image={partnersImage}
          title={"What you see is what you get"}
          description={
            "We care about understanding our customers needs, we've always built successful and long term partnerships!"
          }
          isMobile={isMobile}
        />
      </div>
    </div>
  );
};
